/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import StatusColumnFormatter from "../components/StatusColumnFormatter";
import TypeColumnFormatter from "../components/TypeColumnFormatter";
import UserDialog from "../components/UserDialog";
import apiUrl from "../../../../configs/urls";
import { getApiToken } from "../../../../redux/apis";
import { actions as manageUsersActions } from "../redux/manageUsersRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import UsersFilterForm from "../components/UsersFilterForm";

export default function ManageUsers() {
  const dispatch = useDispatch();
  const {
    listLoading,
    usersList,
    totalUsersRecords,
    currentPage,
    maxUsersPerPage,
    usersSortField,
    usersSortOrder,
  } = useSelector((state) => state.manageUsers);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(
      manageUsersActions.getUsersList(
        usersSortField,
        usersSortOrder,
        currentPage,
        maxUsersPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalUsersRecords ? totalUsersRecords : 0,
    sizePerPage: maxUsersPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if(page !== 0){
      dispatch(
        manageUsersActions.changeCurrentPage(
          page - 1,
          maxUsersPerPage,
          cookies.accessToken
        )
      );}
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== usersSortField || sortOrder !== usersSortOrder) {
        dispatch(
          manageUsersActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const applyFilter = () => {
    dispatch(manageUsersActions.setFilter(true));
    dispatch(manageUsersActions.setCurrentPage(0));
    dispatch(
      manageUsersActions.getUsersList(
        usersSortField,
        usersSortOrder,
        0,
        maxUsersPerPage,
        cookies.accessToken
      )
    );
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageUsersActions.closeUserDialog());
  };

  const openEditUserDialog = (edit, id) => {
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: function(cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "username",
      text: "Username",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "role_name",
      text: "Role",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditUserDialog: (id) => openEditUserDialog(true, id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Users list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <UsersFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-blue-primary"
            onClick={() => openEditUserDialog(false, null)}
          >
            New User
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter /> */}
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <Table
          listLoading={listLoading}
          entities={usersList ? usersList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalUsersRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: usersSortField, order: usersSortOrder }}
        />
      </CardBody>
      <UserDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
