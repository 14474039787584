/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/RoleActionsColumnFormatter";
import StatusColumnFormatter from "../components/RoleStatusColumnFormatter";
import TypeColumnFormatter from "../components/RoleTypeColumnFormatter";
import RoleDialog from "../components/RoleDialog";
import apiUrl from "../../../../configs/urls";
import { getApiToken } from "../../../../redux/apis";
import { actions as manageRolesActions } from "../redux/manageRolesRedux";
import RoleFilterForm from "../components/RoleFilterForm";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";

// import { CustomersGrouping } from "./customers-grouping/CustomersGrouping";
// import { useCustomersUIContext } from "./CustomersUIContext";

let entities = [
  {
    id: 1,
    display_name: "Admin",
    status: 1,
  },
  {
    id: 2,
    display_name: "Sub Admin",
    status: 0,
  },
];

export default function ManageRoles() {
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();

  const [cookies] = useCookies(["accessToken"]);

  const {
    listLoading,
    rolesList,
    totalRolesRecords,
    currentPage,
    maxRolesPerPage,
    rolesSortField,
    rolesSortOrder,
  } = useSelector((state) => state.manageRoles);

  useEffect(() => {
    dispatch(
      manageRolesActions.getRolesList(
        rolesSortField,
        rolesSortOrder,
        currentPage,
        maxRolesPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const paginationOptions = {
    custom: true,
    totalSize: totalRolesRecords ? totalRolesRecords : 0,
    sizePerPageList: [{ text: "10", value: 10 }],
    sizePerPage: maxRolesPerPage,
    page: currentPage ? currentPage + 1 : 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if(page !== 0){
      dispatch(
        manageRolesActions.changeCurrentPage(
          page - 1,
          maxRolesPerPage,
          cookies.accessToken
        )
      );}
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== rolesSortField || sortOrder !== rolesSortOrder) {
        dispatch(
          manageRolesActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageRolesActions.closeUserDialog());
  };

  const openEditUserDialog = (id) => {
    setEdit(true);
    setDialogID(id);
    setShowModal(true);
  };

  const openNewUserDialog = () => {
    setEdit(false);
    setShowModal(true);
  };

  const applyFilter = () => {
    dispatch(manageRolesActions.setFilter(true));
    dispatch(manageRolesActions.setCurrentPage(0));
    dispatch(
      manageRolesActions.getRolesList(
        rolesSortField,
        rolesSortOrder,
        0,
        maxRolesPerPage,
        cookies.accessToken
      )
    );
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: function(cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "display_name",
      text: "Role Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditUserDialog: (id) => openEditUserDialog(id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <Card>
      <CardHeader title="Role list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <RoleFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-blue-primary"
            onClick={() => openNewUserDialog()}
          >
            New Role
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter /> */}
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <Table
          listLoading={listLoading}
          entities={rolesList ? rolesList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalRolesRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: rolesSortField, order: rolesSortOrder }}
        />
      </CardBody>
      <RoleDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
