/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import './custom.scss';
import {
  sortCaret,
  headerSortingClasses, toAbsoluteUrl
} from "../../../../_metronic/_helpers";

import Table from "../../../components/Table";
import PhoneInput from "react-phone-input-2";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
//import { Field } from "formik";

function ClientSubuserList({ clientID, activetab }) {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [phoneCode, setPhoneCode] = useState('us');
  const handleClose = () => setShowModal(false);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState(1);
  const [id, setId] = useState();
  const [getUserType, setUserType] = useState('');
  const [getError, setError] = useState({});
  const [isPrioritizationAllowed, setIsPrioritizationAllowed] = useState(false);
  const {
    clientSubuserList,
    getClientSubuserLoading,
    totalClientSubuserRecords,
    currentPageSubuser,
    maxSubuserPerPage,
    clientsSortFieldSubuser,
    clientsSortOrderSubuser,
    saveSubuserDataSuccess,
    saveSubuserDataLoading,
    subUserData,
    editSubuserDataSuccess,
    editSubuserDataLoading,
    saveUpdatedSubuserDataSuccess,
    userTypeData,
    userTypeDataLoading,
    userTypeDataFailed
  } = useSelector((state) => state.manageClients);
  const [formLoading, setFormLoading] = useState(true);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (activetab === "subusers") {
      if(currentPageSubuser){
        dispatch(
          manageClientsActions.changeCurrentPageSubuser(
            clientID,
            0,
            maxSubuserPerPage,
            cookies.accessToken
          )
        );
      }else{
        dispatch(
          manageClientsActions.getClientSubuserList(
            clientID,
            clientsSortFieldSubuser,
            clientsSortOrderSubuser,
            currentPageSubuser,
            maxSubuserPerPage,
            cookies.accessToken
          )
        );
      }
    }
  }, [activetab]);

  useEffect(() => {
    if (saveSubuserDataSuccess || saveUpdatedSubuserDataSuccess) {
      setShowModal(false);
      dispatch(
        manageClientsActions.getClientSubuserList(
          clientID,
          clientsSortFieldSubuser,
          clientsSortOrderSubuser,
          currentPageSubuser,
          maxSubuserPerPage,
          cookies.accessToken
        )
      );
    }
  }, [saveSubuserDataSuccess, saveUpdatedSubuserDataSuccess]);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalClientSubuserRecords ? totalClientSubuserRecords : 0,
    sizePerPage: maxSubuserPerPage,
    page: currentPageSubuser + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          manageClientsActions.changeCurrentPageSubuser(
            clientID,
            page - 1,
            maxSubuserPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== clientsSortFieldSubuser ||
        sortOrder !== clientsSortOrderSubuser
      ) {
        dispatch(
          manageClientsActions.changeSortSubuser(
            clientID,
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: function (cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "group_name",
      text: "Group",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      formatter: function (cell, row) {
        if (row.status === 1) {
          return 'Active';
        } else {
          return 'Inactive';
        }
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "is_request_prioritisation_allowed",
      text: "Prioritization allowed",
      sort: true,
      formatter: function (cell, row) {
        if (row.is_request_prioritisation_allowed === 0) {
          return 'No';
        } else
          return 'Yes';
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: function (cell, row) {
        return (
          <button
            title="Edit user"
            className="btn btn-icon btn-light btn-hover-primary btn-sm mx-6"
            onClick={() => openEditUserDialog(row.id)}
          >
            <span className="svg-icon svg-icon-md svg-icon-primary">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
              />
            </span>
          </button>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const validate = (type = ["name", "email", "userType", "phone"]) => {
    let allValid = true;
    if (type.includes('name')) {
      if (!fullName || typeof fullName !== "string") {
        setError((prevState) => ({ ...prevState, name: "Please Enter Valid Name" }));
        allValid = false;
      }
      else {
        setError((prevState) => ({ ...prevState, name: null }));
      }
    }

    if (type.includes('email')) {
      const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!emailRegEx.test(String(email).toLowerCase())) {
        setError((prevState) => ({ ...prevState, email: "Please Enter Valid Email" }));
        allValid = false;
      }
      else {
        setError((prevState) => ({ ...prevState, email: null }));
      }
    }

    if (type.includes('userType')) {
      if (!getUserType) {
        setError((prevState) => ({ ...prevState, userType: "Please Select User Type" }));
        allValid = false;
      }
      else {
        setError((prevState) => ({ ...prevState, userType: null }));
      }
    }

    if (type.includes('phone')) {
      if (!phoneNo || phoneNo.length < 6) {
        setError((prevState) => ({ ...prevState, phone: "Please Enter Valid Phone Number" }));
        allValid = false;
      }
      else {
        setError((prevState) => ({ ...prevState, phone: null }));
      }
    }

    return allValid;
  }

  const openEditUserDialog = (id) => {
    setEdit(true);
    setShowModal(true);
    setId(id);
    dispatch(
      manageClientsActions.getSubUserData(
        id,
        cookies.accessToken
      )
    );
  }
  useEffect(() => {
    if (editSubuserDataSuccess) {
      setFullName(subUserData.name);
      setEmail(subUserData.email);
      setPhoneCode(subUserData.phone_code);
      setPhoneNo(subUserData.phone)
      setStatus(subUserData.status);
      setUserType(subUserData?.group_id || "");
      setIsPrioritizationAllowed(subUserData.is_request_prioritisation_allowed == 1);
    }
  }, [editSubuserDataSuccess])

  const handleChangeFullName = (e) => {
    setFullName(e.target.value);
  }
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }
  const handleChangePrioritizationAllowed = (e) => {
    setIsPrioritizationAllowed(e.target.checked);
  }
  const handleChangePhone = (v, c, e, f) => {
    setPhoneCode(c["dialCode"]);
    setPhoneNo(v);
  }
  const handleSave = (e) => {
    e.preventDefault();
    if (validate()) {
      if (edit) {
        dispatch(
          manageClientsActions.saveUpdatedSubUserData(
            id,
            fullName,
            email,
            phoneCode,
            phoneNo,
            status,
            getUserType,
            cookies.accessToken,
            isPrioritizationAllowed
          )
        );
      } else {
        dispatch(
          manageClientsActions.addSubUserData(
            clientID,
            fullName,
            email,
            phoneCode,
            phoneNo,
            getUserType,
            cookies.accessToken,
            isPrioritizationAllowed
          )
        );
      }
    }
  }
  useEffect(() => {
    if (saveSubuserDataLoading || (editSubuserDataLoading && edit)) {
      setFormLoading(true);
    } else {
      setFormLoading(false);
    }
  }, [saveSubuserDataLoading, editSubuserDataLoading]);

  useEffect(() => {
    if (edit)
      setTitle('Edit Sub User');
    else
      setTitle('Add Sub User');
  }, [edit])

  const handleChangeStatus = (e) => {
    setStatus(e.target.value);
  }

  const handleChangeUserType = (e) => {
    setUserType(e.target.value);
  }

  const handleShow = () => {
    setEdit(false);
    setShowModal(true)
    setFullName('');
    setEmail('');
    setPhoneCode('us');
    setPhoneNo('')
    setStatus('');
    setIsPrioritizationAllowed(false);
  }

  const resetFormData = () => {
    setFullName('');
    setEmail('');
    setPhoneCode('us');
    setPhoneNo('')
    setStatus(1);
    setUserType('');
    setIsPrioritizationAllowed(false);
  }

  useEffect(() => {
    if (showModal) {
      dispatch(manageClientsActions.getUserTypeData(cookies.accessToken));
    } else {
      resetFormData();
      setError({});
    }
  }, [showModal])

  useEffect(() => {
    return () => {
      dispatch(manageClientsActions.clearClientAccountDetails());
    };
  },[])
  
  return (
    <>
      <Table
        listLoading={getClientSubuserLoading}
        entities={clientSubuserList ? clientSubuserList : []}
        columns={tableColumns}
        paginationOptions={paginationOptions}
        totalCount={totalClientSubuserRecords}
        handleTableChange={(type, newState) =>
          handleTableChange(type, newState)
        }
        sortState={{
          clientID: clientID,
          dataField: clientsSortFieldSubuser,
          order: clientsSortOrderSubuser,
        }}
      />
      <Button variant="primary" onClick={handleShow} style={{ float: "right" }}>
        <i className="fas fa-plus-circle"></i>Add Sub User
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
          <Button variant="secondary" onClick={handleClose} className="close-form">
            <i className="fas fa-times-circle"></i>
          </Button>
        </Modal.Header>
        <Modal.Body className="overlay overlay-block cursor-default">
          {formLoading && (
            <div className="overlay-layer" style={{ zIndex: 99 }}>
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
          <form>
            <div className="myprofilebox subuserbox">
              {/* <span className="brand-logo" style={{ width: "100%", textAlign: "center", display: "block", marginBottom: "1.5em" }}>
                <img alt="logo" src={toAbsoluteUrl("/media/logos/logo.svg")} style={{ maxWidth: "250px", display: "inline-block" }} />
              </span> */}
              <div className="fieldset">
                <div className="form-group">
                  <label className="label required" htmlFor="name" style={{ width: "100%", display: "block", background: "transparent" }}>Full Name</label>
                  <input required id="name" type="text" name="name" className="input-text form-control" placeholder="Full Name"
                    value={fullName}
                    onChange={(e) => handleChangeFullName(e)}
                    onBlur={() => validate(['name'])} />
                  {getError?.name && <span className="error" style={{ color: 'red' }}>{getError?.name}</span>}
                </div>
                <div className="form-group">
                  <label className="label required" htmlFor="email" style={{ width: "100%", display: "block", background: "transparent" }}>Email</label>
                  <input required id="email" type="email" name="email" className="input-text form-control" placeholder="Enter your email"
                    value={email}
                    onChange={(e) => handleChangeEmail(e)}
                    onBlur={() => validate(['email'])} />
                  {getError?.email && <span className="error" style={{ color: 'red' }}>{getError?.email}</span>}
                </div>

                <div className="form-group">
                  <label className="label required" htmlFor="email" style={{ width: "100%", display: "block", background: "transparent" }}>Phone No</label>
                  <PhoneInput
                    country={edit ? undefined : phoneCode}
                    type="text"
                    value={phoneNo}
                    onChange={(v, c, e, f) => handleChangePhone(v, c, e, f)}
                    onBlur={() => validate(['phone'])}
                  />
                  {getError?.phone && <span className="error" style={{ color: 'red' }}>{getError?.phone}</span>}
                </div>

                <div className="form-group">
                  <label
                    className="label required"
                    htmlFor="userType"
                    style={{ width: "100%", display: "block", background: "transparent" }}
                  >User Type</label>
                  <select
                    id="userType"
                    onChange={handleChangeUserType}
                    value={getUserType}
                    name="userType"
                    className='form-control form-control-solid is-valid-select'
                    disabled={userTypeDataLoading || userTypeDataFailed}
                    required
                    onBlur={() => validate(['userType'])}
                  >
                    {userTypeDataLoading ? <option value="">Loading....</option> : null}
                    {!getUserType ? <option value="">Select</option> : null}
                    {Object.keys(userTypeData).map(key => (<option value={key} key={key}>{userTypeData[key]}</option>))}
                  </select>
                  {getError?.userType && <span className="error" style={{ color: 'red' }}>{getError?.userType}</span>}
                </div>

                <div className="form-group">
                  <input style={{position: "relative", top: "2px"}} required id="is_request_prioritization_allowed" type="checkbox" name="is_request_prioritization_allowed" 
                    value={isPrioritizationAllowed} onChange={(e) => handleChangePrioritizationAllowed(e)}
                    checked={isPrioritizationAllowed}/>
                  <label className="label required" htmlFor="is_request_prioritization_allowed" 
                    style={{ width: "100%", display: "inline", background: "transparent" }}>&nbsp;Request prioritization allowed</label>
                </div>

                {edit && (
                  <div className="form-group">
                    <label className="label required" htmlFor="status" style={{ width: "100%", display: "block", background: "transparent" }}>Status</label>
                    <select
                      id="status"
                      onChange={(e) => handleChangeStatus(e)}
                      value={status}
                      name="status"
                      className='form-control form-control-solid is-valid-select'
                    >
                      <option value="0">Inactive</option>
                      <option value="1">Active</option>
                      {/* <option value="2">Inactive</option> */}
                    </select>
                  </div>
                )
                }
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSave} disabled={formLoading}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ClientSubuserList;