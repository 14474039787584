/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Popover from "@material-ui/core/Popover";
import SVG from "react-inlinesvg";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ActionsColumnFormatter";
import StatusColumnFormatter from "../components/StatusColumnFormatter";
import UserDialog from "../components/UserDialog";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import ClientsFilterForm from "../components/ClientsFilterForm";
import { useHistory } from "react-router-dom";

export default function ClientListing() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    clientsList,
    listLoading,
    totalClientsRecords,
    currentPage,
    maxClientsPerPage,
    clientsSortField,
    clientsSortOrder,
  } = useSelector((state) => state.manageClients);
  const [showModal, setShowModal] = useState(false);
  const [edit, setEdit] = useState(true);
  const [dialogID, setDialogID] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    dispatch(
      manageClientsActions.getClientsList(
        clientsSortField,
        clientsSortOrder,
        currentPage,
        maxClientsPerPage,
        cookies.accessToken
      )
    );
  }, []);

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalClientsRecords ? totalClientsRecords : 0,
    sizePerPage: maxClientsPerPage,
    page: currentPage + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          manageClientsActions.changeCurrentPage(
            page - 1,
            maxClientsPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (sortField !== clientsSortField || sortOrder !== clientsSortOrder) {
        dispatch(
          manageClientsActions.changeSort(
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const applyFilter = () => {
    dispatch(manageClientsActions.setFilter(true));
    dispatch(manageClientsActions.setCurrentPage(0));
    dispatch(
      manageClientsActions.getClientsList(
        clientsSortField,
        clientsSortOrder,
        0,
        maxClientsPerPage,
        cookies.accessToken
      )
    );
  };

  const onHideUserDialog = () => {
    setShowModal(false);
    setDialogID(null);
    dispatch(manageClientsActions.closeClientDialog());
  };

  const openNewClientDialog = (edit, id) => {
    dispatch(manageClientsActions.closeClientDialog());
    setEdit(edit);
    setDialogID(id);
    setShowModal(true);
  };

  const openEditClientDialog = (edit, id) => {
    dispatch(manageClientsActions.closeClientDialog());
    history.push("/client-settings/manage-clients/" + id + "");
  };

  const ClientsStatusColumnFormatter = (text) => {
    if (text === "standard") {
      return "Standard";
    } else if (text === "enterprise") {
      return "Enterprise";
    }
    return "";
  }

  const tableColumns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      formatter: function (cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "company_name",
      text: "Company Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "client_type",
      text: "Client Type",
      sort: true,
      formatter: ClientsStatusColumnFormatter,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "phone",
      text: "Phone",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => {
        return "+" + cell;
      },
    },
    {
      dataField: "is_sandbox",
      text: "Test User",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => {
        return cell == "1"?"Yes":"No";
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      formatter: StatusColumnFormatter,
      headerSortingClasses,
    },
    {
      dataField: "is_archieve",
      text: "Archive",
      // sort: true,
      // sortCaret: sortCaret,
      // headerSortingClasses,
      formatter: (cell, row) => {
        return cell == "1"?"Yes":"No";
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditClientDialog: (id) => openEditClientDialog(true, id),
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Card>
      <CardHeader title="Clients list">
        <CardHeaderToolbar>
          <div style={{ marginRight: "10px" }}>
            <button
              type="button"
              className="btn btn-outline-primary"
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              <span className="svg-icon svg-icon-sm svg-icon-gray">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Text/Filter.svg")} />
              </span>
              Filter
            </button>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <ClientsFilterForm
                applyFilter={() => applyFilter()}
                onHide={() => setAnchorEl(null)}
              />
            </Popover>
          </div>
          <button
            type="button"
            className="btn btn-blue-primary"
            onClick={() => openNewClientDialog(false, null)}
          >
            New Client
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CustomersFilter /> */}
        {/* {customersUIProps.ids.length > 0 && <CustomersGrouping />} */}
        <Table
          listLoading={listLoading}
          entities={clientsList ? clientsList : []}
          columns={tableColumns}
          paginationOptions={paginationOptions}
          totalCount={totalClientsRecords}
          handleTableChange={(type, newState) =>
            handleTableChange(type, newState)
          }
          sortState={{ dataField: clientsSortField, order: clientsSortOrder }}
        />
      </CardBody>
      <UserDialog
        id={dialogID}
        show={showModal}
        edit={edit}
        onHide={() => onHideUserDialog()}
      />
    </Card>
  );
}
