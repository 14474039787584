import React, {useState} from "react";
import {FieldFeedbackLabel} from "../../_metronic/_partials/controls/forms/FieldFeedbackLabel";
import PhoneInput from "react-phone-input-2";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function CustomPhoneInput({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  meta,
  ...props
}) {
  return (
    <>
      {label && <label>Enter {label}</label>}
      <PhoneInput
        inputProps={{
          name: field.name,
          required: true,
          autoFocus: true,
        }}
        country={props.country}
        type={type}
        value={props.value}
        onChange={(v, c, e, f) => props.onChange(v, c, e, f)}
        onBlur={field.onBlur}
        inputClass={getFieldCSSClasses(touched[field.name], errors[field.name])}
        disabled={props.disabled}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={type}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
