import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  setAuthorization: "[Set Authorization] Action",
  setShouldLogout: "[Set Should Logout] Action"
};

const initialAuthState = {
  isAuthorized: undefined,
  userID: undefined,
  user_name: undefined,
  username: undefined,
  email: undefined,
  roleID: undefined,
  role: undefined,
  shouldLogout: false
};

export const reducer = persistReducer(
  { storage, key: "v711-demo1-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { user } = action.payload;
        return { 
          isAuthorized: true,
          userID: user.id,
          user_name: user.name,
          username: user.username,
          email: user.email,
          roleID: user.roles[0] ? user.roles[0].id : undefined,
          role: user.roles[0] ? user.roles[0].name : undefined,
          shouldLogout: false 
        };
      }

      case actionTypes.setAuthorization: {
        return { ...state, isAuthorized: action.payload.setAuthorization }
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return {
          ...state,
          userID: user.id,
          user_name: user.name,
          username: user.username,
          email: user.email,
          roleID: user.roles[0] ? user.roles[0].id : undefined,
          role: user.roles[0] ? user.roles[0].display_name : undefined 
        };
      }

      case actionTypes.setShouldLogout: {
        const { flag } = action.payload;
        return {
          ...state,
          shouldLogout: flag
        }
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => ({ type: actionTypes.Login, payload: { user } }),
  setAuthorization: (flag) => ({ type: actionTypes.setAuthorization, payload: {setAuthorization: flag} }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setShouldLogout: flag => ({ type: actionTypes.setShouldLogout, payload: { flag }})
};

export function* saga() {
  // yield takeLatest(actionTypes.Login, function* loginSaga() {
    
  // });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.requestUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const user = {
  //     name: 'admin',
  //     username: 'admin',
  //     role: 'admin'
  //   }

  //   yield put(actions.fulfillUser(user));
  // });
}
