import React, { useContext, useEffect, useState } from "react";
import { getApi } from "../redux/apis";
import apiUrl from "../configs/urls";
// import LoaderComponent from "../components/Loader";

const initialValue = {

};
const ConfigContext = React.createContext(initialValue);

const ConfigProvider = ({children}) => {
  const [config, setConfig] = useState();

  useEffect(()=>{
    getApi(apiUrl("CONFIGS"),null)
    .then(res=>res.json())
    .then(res=>{
      const {data={}} = res;
      const {dataTable=[]} = data;
      const mapped = dataTable.map((i)=>({[i.slug]:i}))
      const configData = Object.assign({},...mapped);
      setConfig(configData);
      window.project_name = configData?.project_name?.value || "";
      const colors = dataTable.filter((i)=>i.slug.includes("color"));
      if(colors.length){
        const colorStrings = colors.map(i=>`--${i.slug}: ${i.value};`)
        var styles = `
          :root {
            ${colorStrings.join("\r\n")}
          }
        `
        var styleSheet = document.createElement("style")
        styleSheet.innerText = styles
        document.head.appendChild(styleSheet)
      }
    })
    .catch(e=>{
      console.log(e);
    })
  },[])

  useEffect(()=>{
    if(typeof window !== "undefined" && config?.page_title?.value){
      document.title = config?.page_title?.value;
    }
    if(typeof window !== "undefined" && config?.favicon?.value){
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
          link = document.createElement('link');
          link.rel = 'icon';
          document.head.appendChild(link);
      }
      link.href = config?.favicon?.value;
    }
  },[config])


  if(!config){
    return <div>Loading...</div>
  }

  return (
    <ConfigContext.Provider
      value={config}
    >
      {children}
    </ConfigContext.Provider>
  );
};
function useConfig() {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within an ConfigProvider");
  }
  return context;
}
export { ConfigContext, ConfigProvider, useConfig };
