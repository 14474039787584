import axios from "axios";
import config from "../../../../configs/config";

export const LOGIN_URL = "api/admin/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/admin/auth/forget";
export const RESET_PASSWORD_URL = "api/admin/auth/reset";

export const ME_URL = "api/me";

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Accept: "application/json"
};

const headersWithToken = (accessToken) => ({
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Authorization: `Bearer ${accessToken}`,
});

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: `email=${email}`,
  };
  return fetch(`${config.API_ENDPOINT}${REQUEST_PASSWORD_URL}`, requestOptions);
}

export function resetPassword(email, password, confirmpassword, token) {
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: `email=${email}&password=${password}&confirm-password=${confirmpassword}&token=${token}`,
  };
  return fetch(`${config.API_ENDPOINT}${RESET_PASSWORD_URL}`, requestOptions);
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}
