const dev = {
  API_ENDPOINT: "http://127.0.0.1:5001/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LcZZBwoAAAAAPHrU7_BGitMeBoq3zK2NxMlETSF",
  FRONT_URL: "http://localhost:5002/"
};

const stage = {
  API_ENDPOINT: "https://stagingapi.cabinetiq.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LcZZBwoAAAAAPHrU7_BGitMeBoq3zK2NxMlETSF", //for staging key 6LeyuewZAAAAAPzVWBaWQtoX1cyuY1FpHwS1Czhd
  FRONT_URL: "https://stagingdesign.cabinetiq.com/"
};

const prod = {
  API_ENDPOINT: "https://designapi.cabinetiq.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LcZZBwoAAAAAPHrU7_BGitMeBoq3zK2NxMlETSF", // 6LeJdCoaAAAAADqzSeExaipW4L_9Iz_6hldcQN1F
  FRONT_URL: "https://design.cabinetiq.com/"
};

function getConfig() {
  if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'staging') {
    return stage
  } else if (process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV === 'production') {
    return prod;
  } else {
    return dev;
  }
}

const config = getConfig();

export default config;

