import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

export default function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditUserDialog, openViewUserDialog }
) {
  return (
    <>
      <a
        title="Edit user"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-6"
        onClick={() => openEditUserDialog(row.id)}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </a>
    </>
  );
}
