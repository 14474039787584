import config from "./config";

const url = {
  CONFIGS: "api/configs",
  ADMIN_CONFIGS: "api/admin/configs",
  LOGIN_URL: "api/admin/auth/login",
  GET_TOKEN_USER_URL: "api/admin/auth/user",
  USER_ADMIN_URL: "api/admin/adminuser",
  ROLES_DROPDOWN_URL: "api/admin/roles/dropdown",
  ROLE_LIST_URL: "api/admin/roles",
  CREATE_ROLE_URL: "api/admin/roles",
  SHOW_ROLE_URL: "api/admin/roles",
  UPDATE_ROLE_URL: "api/admin/roles",
  PERMISSION_LIST_URL: "api/admin/permissions",
  CLIENT_LIST_URL: "api/admin/clients",
  CREATE_CLIENT_URL: "api/admin/clients",
  GET_CLIENT_URL: "api/admin/clients",
  UPDATE_CLIENT_URL: "api/admin/clients",
  GET_CLIENT_API_ACCESS_KEY_URL: "api/admin/client/get-api-access-key",
  REGENERATE_CLIENT_API_ACCESS_KEY_URL:
    "api/admin/client/regenerate-api-access-key",
  CLIENT_RESOURCE_SEARCH_URL: "api/admin/get-designers",
  ADD_CLIENT_RESOURCE_URL: "api/admin/clients/add-resources",
  GET_CLIENT_RESOURCE_URL: "api/admin/clients/resource-list",
  REMOVE_CLIENT_RESOURCE_URL: "api/admin/clients/resource-remove",
  SET_PM_CLIENT_RESOURCE_URL: "api/admin/clients/resource-set-position",
  GET_CLIENT_SUBUSER_URL: "api/admin/clients/subuser-list",
  RESEND_EMAIL_URL: "api/admin/clients/resend-email",
  DESIGNER_LIST_URL: "api/admin/designers",
  GET_POSITIONS_DROPDOWN_URL: "api/admin/get-position",
  GET_DESIGNER_URL: "api/admin/designers",
  UPDATE_DESIGNER_URL: "api/admin/designers",
  SAVE_DESIGNER_URL: "api/admin/designers",
  GET_CATEGORY_LIST_URL: "api/admin/categoryoption",
  GET_CLIENTS_DROPDOWN_URL: "api/admin/categoryoption/create",
  SAVE_CATEGORY_DATA_URL: "api/admin/categoryoption",
  EDIT_CATEGORY_DATA_URL: "api/admin/categoryoption",
  UPDATE_CATEGORY_DATA_URL: "api/admin/categoryoption",
  GET_CATEGORIES_URL: "api/admin/category",
  DELETE_CATEGORY_OPTION: "api/admin/categoryoption",
  CABINATE_BRAND_OPTION_URL: "api/admin/cabinet-brand-list",
  GET_EMAIL_NOTIFICATION_DATA_URL: "api/admin/settings",
  UPDATE_EMAIL_NOTIFICATION_DATA_URL: "api/admin/settings",
  LOGIN_AS_CLIENT_URL: "api/admin/client/login-as-client",
  SAVE_SUBUSER_DATA_URL: "api/admin/client/client-admin",
  EDIT_SUBUSER_DATA_URL: "api/admin/client/show-client-admin",
  SAVE_UPDATED_SUBUSER_DATA_URL: "api/admin/client/update-client-admin",
  USER_TYPE_DATA_URL: "api/admin/client/user-group-dropdown",
  GET_APPLIANCES_CATEGORIES_URL: "api/admin/appliences-list",
  GET_FILTER_URL: "api/admin/reports/filter-data",
  DOWNLOAD_EXCEL_URL: "api/admin/reports/download",
  GET_CLIENT_ACTIVITY_URL: "api/admin/clients/activity-logs-list",
  GET_CLIENT_ACTIVITY_FILTER_URL: "api/admin/clients/activity-logs/filter-data",
  GET_ANOUNCEMENT_URL: "api/admin/clients/announcements/details",
  UPDATE_ANNOUNCEMENT_DATA_URL: "api/admin/clients/announcements/update"
};

const apiUrl = (name) => {
  return `${config.API_ENDPOINT}${url[name]}`;
};

export default apiUrl;
