import { put, takeLatest, call, all } from "redux-saga/effects";
import ShortUniqueId from "short-unique-id";
import { getApiToken, putApiTokenJson } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_GENERAL_CONFIGS: "GET_GENERAL_CONFIGS",
  SAVE_GENERAL_CONFIG_RESDATA: "SAVE_GENERAL_CONFIG_RESDATA",
  SAVE_GENERAL_CONFIG_DATA: "SAVE_GENERAL_CONFIG_DATA"
};

const initialeState = {
  configs:[]
};

export const reducer = (state = initialeState, action) => {
  switch (action.type) {
    case actionTypes.SAVE_GENERAL_CONFIG_RESDATA: 
      return {
        ...state,
        configs: action.payload,
      };

    default:
      return state;
  }
};

export const actions = {
  getGeneralConfigs: (accessToken) => ({
    type: actionTypes.GET_GENERAL_CONFIGS,
    payload: {accessToken},
  }),
  saveGeneralConfigsRes: (data) => ({
    type: actionTypes.SAVE_GENERAL_CONFIG_RESDATA,
    payload: data,
  }),
  saveGeneralConfigs: (data,accessToken) => ({
    type: actionTypes.SAVE_GENERAL_CONFIG_DATA,
    payload: {data, accessToken},
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.GET_GENERAL_CONFIGS,
    getGeneralConfigsSaga
  );
  yield takeLatest(
    actionTypes.SAVE_GENERAL_CONFIG_DATA,
    saveGeneralConfigsSaga
  );
}

function* getGeneralConfigsSaga(action) {
  let { accessToken } = action.payload;
  try {
    const response = yield call(
      getApiToken,
      apiUrl("ADMIN_CONFIGS"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.saveGeneralConfigsRes(responseData.data.dataTable)),
        ]);
      } else {
        yield put(generalActions.addToast(
          "Save General Configs",
          responseData.message,
          "error",
          uid()
        ))
      }
    } else {
      yield put(
        generalActions.addToast(
          "Save General Configs",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      generalActions.addToast(
        "Save General Configs",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveGeneralConfigsSaga(action) {
  let { accessToken, data } = action.payload;
  try {
    const response = yield call(
      putApiTokenJson,
      apiUrl("ADMIN_CONFIGS"),
      JSON.stringify(data),
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put(
          generalActions.addToast(
          "Save General Configs",
          responseData.message || "Config saved successfully",
          "success",
          uid()
          )
        )
      } else {
        yield put(
          generalActions.addToast(
          "Save General Configs",
          responseData.message,
          "error",
          uid()
          )
        )
      }
    } else {
      yield put(
        generalActions.addToast(
          "Save General Configs",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(
      generalActions.addToast(
        "Save General Configs",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}