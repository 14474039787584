/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";

import {
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers";

// import { CustomersFilter } from "./customers-filter/CustomersFilter";
import Table from "../../../components/Table";
import ActionsColumnFormatter from "../components/ResourceActionsColumnFormatter";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";

export default function ClientResourceList({ clientID, activetab }) {
  const dispatch = useDispatch();
  const {
    clientResourceList,
    getClientResourceLoading,
    totalClientResourceRecords,
    currentPageResource,
    maxResourcePerPage,
    clientsSortFieldResource,
    clientsSortOrderResource,
  } = useSelector((state) => state.manageClients);

  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (activetab === "resources") {
      if(currentPageResource){
        dispatch(
          manageClientsActions.changeCurrentPageResource(
            clientID,
            0,
            maxResourcePerPage,
            cookies.accessToken
          )
        );
      }else{
        dispatch(
          manageClientsActions.getClientResourceList(
            clientID,
            clientsSortFieldResource,
            clientsSortOrderResource,
            currentPageResource,
            maxResourcePerPage,
            cookies.accessToken
          )
        );
      }
    }
  }, [activetab]);

  useEffect(() => {
    return () => {
      dispatch(manageClientsActions.clearClientAccountDetails());
    };
  },[])

  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalClientResourceRecords ? totalClientResourceRecords : 0,
    sizePerPage: maxResourcePerPage,
    page: currentPageResource + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if(page !== 0){
      dispatch(
        manageClientsActions.changeCurrentPageResource(
          clientID,
          page - 1,
          maxResourcePerPage,
          cookies.accessToken
        )
      );}
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== clientsSortFieldResource ||
        sortOrder !== clientsSortOrderResource
      ) {
        dispatch(
          manageClientsActions.changeSortResource(
            clientID,
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const removeClientResource = (resourceID, clientID) => {
    dispatch(
      manageClientsActions.removeClientResource(
        clientID,
        resourceID,
        cookies.accessToken
      )
    );
  };

  const setAsPM = (resourceID, clientID) => {
    dispatch(
      manageClientsActions.resourceSetPM(
        clientID,
        resourceID,
        cookies.accessToken
      )
    );
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: function(cell,row) {
        return row.DT_RowIndex;
        },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "designation",
      text: "Designation",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        removeClientResource: (id, clientID) =>
          removeClientResource(id, clientID),
        setAsPM: (id, clientID) => setAsPM(id, clientID),
        clientID: clientID,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <Table
        listLoading={getClientResourceLoading}
        entities={clientResourceList ? clientResourceList : []}
        columns={tableColumns}
        paginationOptions={paginationOptions}
        totalCount={totalClientResourceRecords}
        handleTableChange={(type, newState) =>
          handleTableChange(type, newState)
        }
        sortState={{
          clientID: clientID,
          dataField: clientsSortFieldResource,
          order: clientsSortOrderResource,
        }}
      />
    </>
  );
}
