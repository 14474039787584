import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { useDispatch, useSelector } from "react-redux";

import { Input, Select } from "../../../../_metronic/_partials/controls";
import { actions as intakeFormActions } from "../redux/intakeFormRedux";
import { useCookies } from "react-cookie";
import { CustomMultiSelect } from "../../../components/CustomMultiSelect";

export default function CategoryFilterForm({ onHide, applyFilter }) {
  const dispatch = useDispatch();
  const [cookies] = useCookies(["accessToken"]);
  const { categoryFilterData, cabinateBrandOptionForFilter, applianceOptionForFilter, clientsOptionForFilter, clients, categoryData, selectedClients, cabinateSeletedFilter,
    applianceSelectedFilter, intakeType, defaultFilter, clientsSelectedFilter
  } = useSelector((state) => state.intakeForm);
  categoryData.visibility = selectedClients;
  const handleFormChange = (e) => {
    let data = categoryFilterData;
    data[e.target.name] = e.target.value;
    dispatch(intakeFormActions.changeCategoryFilterData(data));
  };

  const handleClearFilter = () => {
    dispatch(intakeFormActions.setCurrentPage(0));
    dispatch(intakeFormActions.clearCategoryFilterData(cookies.accessToken));
    onHide();
  };

  const setSelectedClients = (val) => {
    dispatch(intakeFormActions.setSelectedClientFilter(val));
  };

  const setCabinateFilter = (val) => {
    dispatch(intakeFormActions.setSelectedCabinateFilter(val));
  };

  const setApplianceFilter = (val) => {
    dispatch(intakeFormActions.setSelectedApplianceFilter(val));
  };

  const changeDefaultFilter = (e) => {
    dispatch(intakeFormActions.setDefaultFilter(e.target.value));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={categoryFilterData}
        handleChange={(e) => handleFormChange(e)}
        onSubmit={() => {
          applyFilter();
          onHide();
        }}
      >
        {({ handleSubmit }) => (
          <>
            <div style={{ padding: "20px 10px 0px 10px" }}>
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Name */}
                  <div className="col-lg-12">
                    <Field
                      value={categoryFilterData.name}
                      name="name"
                      component={Input}
                      placeholder="Name"
                      label="Name"
                      onChange={(e) => handleFormChange(e)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      labelledBy={"Select"}
                      options={clientsOptionForFilter}
                      value={clientsSelectedFilter}
                      onChange={setSelectedClients}
                      name="visibility"
                      component={CustomMultiSelect}
                      label="Clients"
                      overrideStrings={{
                        selectSomeItems: "Select...",
                        allItemsAreSelected: "All clients are selected.",
                        selectAll: "Select All",
                        search: "Search",
                        clearSearch: "Clear Search",
                      }}
                      closeOnChangedValue={true}
                    />
                    </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <label>Is Default</label>
                    <Select
                      value={defaultFilter}
                      
                      onChange={(e) => changeDefaultFilter(e)}
                    >
                      <option key={"Select"} value={""}>Select</option>
                      <option key={"Yes"} value={"1"}>Yes</option>
                      <option key={"No"} value={"0"}>
                            No
                          </option>
                  </Select>
                  <div class="feedback">Please select <b>Is Default</b></div>
                  </div>
                </div>
                  
                {intakeType == "door-style" && <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      labelledBy={"Select"}
                      options={cabinateBrandOptionForFilter}
                      value={cabinateSeletedFilter}
                      onChange={setCabinateFilter}
                      name="visibility"
                      component={CustomMultiSelect}
                      label="Cabinet Brand"
                      overrideStrings={{
                        selectSomeItems: "Select...",
                        allItemsAreSelected: "All clients are selected.",
                        selectAll: "Select All",
                        search: "Search",
                        clearSearch: "Clear Search",
                      }}
                    />
                    </div>
                </div>}
                {intakeType == "appliances-sub-category" && <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      labelledBy={"Select"}
                      options={applianceOptionForFilter}
                      value={applianceSelectedFilter}
                      onChange={setApplianceFilter}
                      name="visibility"
                      component={CustomMultiSelect}
                      label="Appliance"
                      overrideStrings={{
                        selectSomeItems: "Select...",
                        allItemsAreSelected: "All clients are selected.",
                        selectAll: "Select All",
                        search: "Search",
                        clearSearch: "Clear Search",
                      }}
                    />
                    </div>
                </div>}
              </Form>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                flexDirection: "row",
                paddingRight: "10px",
              }}
            >
              <button
                style={{ marginRight: "10px" }}
                type="button"
                onClick={() => handleClearFilter()}
                className="btn btn-light btn-elevate"
              >
                Clear Filter
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Apply
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
