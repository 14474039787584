/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
// import { shallowEqual, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Layout } from "../_metronic/layout";
import * as auth from "./modules/Auth/_redux/authRedux";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import { getUserByToken } from "./modules/Auth/_redux/authCrud";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import { LayoutSplashScreen } from "../_metronic/layout";
import { getApiToken } from "../redux/apis";
import apiUrl from "../configs/urls";

export function Routes(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(["accessToken"]);
  const { isAuthorized, shouldLogout } = useSelector((state) => state.auth);
  const [splashScreen, setSplashScreen] = useState(false);

  useEffect(() => {
    if (cookies.accessToken && !isAuthorized) {
      setSplashScreen(true);
      getApiToken(apiUrl("GET_TOKEN_USER_URL"), null, cookies.accessToken)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            dispatch(auth.actions.setAuthorization(false));
            setSplashScreen(false);
          }
        })
        .then((responseData) => {
          if (responseData.data) {
            dispatch(auth.actions.setShouldLogout(false));
            dispatch(auth.actions.setAuthorization(true));
            dispatch(auth.actions.fulfillUser(responseData.data));
            setSplashScreen(false);
          }
        })
        .catch((error) => {
          dispatch(auth.actions.setAuthorization(false));
          setSplashScreen(false);
        });
    }
  }, [cookies.accessToken, dispatch, isAuthorized]);

  useEffect(() => {
    if (shouldLogout === true) {
      removeCookie("accessToken", { path: "/" });
      dispatch(auth.actions.logout());
    }
  }, [shouldLogout]);

  if (splashScreen) {
    return <LayoutSplashScreen />;
  } else {
    return (
      <Switch>
        {!cookies.accessToken ? (
          /*Render auth page when user at `/auth` and not authorized.*/
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /*Otherwise redirect to root page (`/`)*/
          <Redirect from="/auth" to="/" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={Logout} />

        {!cookies.accessToken ? (
          /*Redirect to `/auth` when user is not authorized*/
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <BasePage />
          </Layout>
        )}
      </Switch>
    );
  }
}
