import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { Fields } from "./Fields";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { actions as genActions } from "../../../../../redux/generalReducer";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId();

const Images = ({allConfigs=[],saveConfigs}) =>{
  const dispatch = useDispatch();
  const [images,setImages] = useState([]);
  const [initialValues,setInitialValues] = useState();
  
  useEffect(()=>{
    const imgs = allConfigs.filter(i=>i.group_name==="image");
    setImages(imgs)
    const newMapped = imgs.map((i)=>({[i.slug]:i.value}));
    const newObj = Object.assign({},...newMapped);
    setInitialValues(newObj)
  },[allConfigs])

  const submitForm = (values)=>{
    let configs = [];
    for(let key in values){
      const value = values[key];
      if(value.includes(";base64")){
        configs.push({slug:key, value:values[key]});
      }
    }
    console.log("configs",configs);
    if(configs.length){
      saveConfigs({configs})
    }else{
      dispatch(
        genActions.addToast(
          "Save General Configs",
          "Not selected any image to change",
          "error",
          uid()
        )
      )
    }
  }

  return (
      <>
      {initialValues?
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {(formik) => (
            <Form className="form form-label-right">
              <Card>
                <CardHeader style={{ border: 0 }} title="Images">
                  <CardHeaderToolbar>
                    <button
                      type="submit"
                      className="btn btn-blue-primary"
                      disabled={formik.isSubmitting}
                    >
                      Save
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Fields fields={images} formik={formik}/>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
        :null
      }
    </>
  )
}

export default Images;