import React from "react";
import { Field } from "formik";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { useState } from "react";
import { useEffect } from "react";


export const Text = ({field}) => {
  return (
    <div className="col-lg-12">
      <Field type={field.type} name={field?.slug} component={Input} placeholder={field?.title} label={field?.title}/>
    </div>
  )
}

export const Color = ({field}) => {
  return (
    <div className="col-xs-12 col-lg-6">
      <div className="color-box-container d-flex align-items-center gap-3">
        <div className="color-box w-100">
          <Field style={{paddingTop:0, paddingBottom:0, paddingLeft:0 }} type={field.type} name={field?.slug} component={Input} placeholder={field?.title} label={field?.title}/>
        </div>
        <div className="color-box-tooltip mt-2 pl-5">
        {field?.tooltip?
          <div className="custom-tooltip pointer">
            <i className="fas fa-info-circle color-black"></i>
            <span className="tooltiptext">{field?.tooltip}</span>
          </div>
          :null}
        </div>
      </div>
    </div>
  )
}

const helpTextForImages = {
  logo:"Accept only jpg, jpeg and png images. Recommendend logo size is 300px * 60px",
  login_page_banner:"Accept only jpg, jpeg and png images. Recommendend Banner size is 1000px * 1000px",
  favicon:"Accept only jpg, jpeg and png images. Recommendend Favicon size is 200px * 200px",
}

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}

export const File = ({field}) => {
  const [imgLink, setImgLink] = useState();

  useEffect(()=>{
    if(field?.value){
      setImgLink(field?.value);
    }
  },[field.value])

  const handleFileRead = async (event) => {
    const file = event.target.files[0]
    const base64 = await convertBase64(file)
    setImgLink(base64);
    field.formik.setFieldValue(field?.slug, base64)
  }

  return (
    <>
    <div className="col-lg-12">
      <label className="fileUpload" htmlFor={field.slug}> 
        <div className="image">
          <img className="upload-image" src={imgLink} width={100} height={100}/>
          <div className="hover-bg">
            <span className="flaticon-edit"></span>
          </div>
        </div>
        {field?.title} 
      </label>
      <input 
          accept="image/*"
          id={field.slug} 
          type={field.type} 
          name={field?.slug} 
          placeholder={field?.title} 
          label={field?.title}
          onChange={(event) => handleFileRead(event)}
          className="form-control"
          style={{display:"none"}}
      />

    </div>
    <div className="col-lg-12">
      <span className="figure-caption">{helpTextForImages[field.slug]}</span>
    </div>
    </>
  )
}

export const ArrayLinks = ({field}) => {
  const [linksData,setLinks] = useState([]);

  useEffect(()=>{
    const links = JSON.parse(field.value) || [];
    setLinks(links);
  },[])

  const updateLinkData = (index,field,value)=>{
    linksData[index][field] = value;
    setLinks([...linksData])
  }

  useEffect(()=>{
    field.formik.setFieldValue(field.slug,JSON.stringify(linksData));
  },[linksData])

  const addNewRow = ()=>{
    linksData.push({text:"",link:""})
    setLinks([...linksData])
  }

  const removeRow = (index)=>{
    linksData.splice(index, 1);
    setLinks([...linksData])
  }
  
  return (
    <div className="col-lg-12">
      <div className="row">
        <div className="col-lg-10">
        <label>{field.title}</label>
        </div>
        <div className="col-lg-2">
          <button className="btn btn-primary" type="button" onClick={()=>addNewRow()}>Add new Links</button>
        </div>
      </div>
      {linksData.map((i,index)=>(
        <div className="row mt-5" key={index}>
          <div className="col-lg-4">
            <label> Link Label </label>
            <input value={i.text} type="text" name={field?.slug+1} placeholder="Link Label" 
              onChange={(e) => updateLinkData(index,"text",e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-lg-6">
            <label> Link </label>
            <input value={i.link} type="text" name={field?.slug+1} placeholder={field?.title} 
              onChange={(e) => updateLinkData(index,"link",e.target.value)}
              className="form-control"
            />
          </div>
          <div className="col-lg-2">
            <br/>
            <button className="btn" type="button" onClick={()=>removeRow(index)}>Remove</button>
          </div>
        </div>
      ))}
    </div>
  )
}


const formFieldType = {
  text: Text,
  file: File,
  array_links: ArrayLinks,
  color:Color,
};

export const FormField = ({field}) => {
  const FieldComponent = field ? formFieldType[field?.type] : null;
  return FieldComponent ? <FieldComponent {...{ field }} /> : null;
}


export const Fields = ({fields,formik}) =>{
  return (
    <div className="field-container">
      {
        fields.map((i,index)=>(
          <div key={index} className="form-group row">
            <FormField field={{...i,formik}}/>
          </div>
        ))
      }
    </div>
  )
}