/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Input, 
  Select 
} from "../../../../_metronic/_partials/controls";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import { Formik, Form, Field } from "formik";
import { CustomMultiSelect } from "../../../components/CustomMultiSelect";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
// import "./index.scss";
import ShortUniqueId from "short-unique-id";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./index.scss";

export default function Announcement() {
  const uid = new ShortUniqueId();
  const dispatch = useDispatch();
  const {apiLoading,anouncement,anouncementClientDropdown, standardClients, enterpriseClients} = useSelector((state) => state.manageClients);
  
  const [activeTab, setActiveTab] = useState("all");
  const [cookies] = useCookies(["accessToken"]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [comment, setComment] = useState("");
  const [tabActiveByApi, setTabActiveByApi] = useState(false);
  const editor = useRef(null);

  useEffect(() => {
    dispatch(
      manageClientsActions.getAnouncementData(cookies.accessToken)
    );
  }, []);
  
  
 const changeClients = (v)=>{
        if(v == 'all'){
          anouncement.is_to_all = 1;
          dispatch(
            manageClientsActions.setSatndardClients([])
          );
          dispatch(
            manageClientsActions.setEnterPriseClients([])
          );
          dispatch(
            manageClientsActions.setAnoucementData({...anouncement})
          );
        }else{
          anouncement.is_to_all = 0;
          dispatch(
            manageClientsActions.setAnoucementData({...anouncement})
          );
        }
        setActiveTab(v);
 }

 const changeStatus = (e) =>{
  anouncement.is_active = e.target.value;
  dispatch(
    manageClientsActions.setAnoucementData({...anouncement})
  );
 }

const quillOptionsCongif = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "clean"],
    ],
  },
  formats: [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'header',
    'list', 'bullet', 'indent', 'align',
    'link',
  ],
};

const updateAnnouncementData = () =>{
  const getFormattedDate = (d) => {
    var date = new Date(d),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-")
  }
  let standardClientsData = [];
  standardClients.forEach(function(v){
    standardClientsData.push(v.value)
  })

  let enterpriseClientsData = [];
  enterpriseClients.forEach(function(v){
    enterpriseClientsData.push(v.value)
  })

  let temp = {};
  let combineClientsArr = standardClientsData.concat(enterpriseClientsData); 
 
  if(anouncement['is_to_all'] == 1){
    temp["is_active"] = anouncement['is_active'];
    temp["is_to_all"] = anouncement['is_to_all'];
    temp["message"] = comment;
    temp["start_date"] = startDate?getFormattedDate(startDate):null;
    temp["end_date"] = endDate?getFormattedDate(endDate):null;
  }else{
    temp["is_active"] = anouncement['is_active'];
    temp["is_to_all"] = anouncement['is_to_all'];
    temp["clients"] = combineClientsArr.toString();
    temp["message"] = comment;
    temp["start_date"] = startDate?getFormattedDate(startDate):null;
    temp["end_date"] = endDate?getFormattedDate(endDate):null;
  }
  
  dispatch(
    manageClientsActions.updateAnnouncementData(temp,cookies.accessToken)
  );
}

useEffect(()=>{
  if(standardClients.length && !enterpriseClients.length && !tabActiveByApi){
    setTabActiveByApi(true);
    setActiveTab('standard');
  }
  if(!standardClients.length && enterpriseClients.length && !tabActiveByApi){
    setTabActiveByApi(true);
    setActiveTab('enterPrise');
  }
  if(standardClients.length && enterpriseClients.length && !tabActiveByApi){
    setTabActiveByApi(true);
    setActiveTab('standard');
  }
},[standardClients])

useEffect(()=>{
  if(anouncement.message){
    setComment(anouncement.message);
  }

  if(anouncement.message){
    setComment(anouncement.message);
  }

  if(anouncement.start_date){
    setStartDate(new Date(anouncement.start_date));
  }

  if(anouncement.end_date){
    setEndDate(new Date(anouncement.end_date));
  }

},[anouncement])

  return (
    <Card>
      <CardHeader title="Announcement">
      </CardHeader>
      <CardBody className="reports-filter">
      <Modal.Body className="overlay overlay-block cursor-default">
          {apiLoading && (
            <div className="overlay-layer bg-transparent">
              <div className="spinner spinner-lg spinner-success" />
            </div>
          )}
      <Formik>
      <Form className="form form-label-right">
       <div className="form-group row client-data announcement-config">
          <div className="col-lg-12">
            <div className="form-group">
              <label style={{display: "block", marginBottom:"15px"}}>Select Clients</label>
              <div className="form-check form-check-inline">
                <button className={anouncement && anouncement.is_to_all?"btn btn-primary":"btn client-tab"} onClick={()=>{changeClients('all')}} type="button">All</button>
                </div>
                <div className="form-check form-check-inline">
                <button className={activeTab == 'standard'?"btn btn-primary":"btn client-tab"} onClick={()=>{changeClients('standard')}} type="button">Standard{standardClients.length ? <span className="client-count">{standardClients.length}</span>:null}</button>
                </div>
                <div className="form-check form-check-inline">
                <button className={activeTab == 'enterprise'?"btn btn-primary":"btn client-tab"} onClick={()=>{changeClients('enterprise')}} type="button">Enterprise{enterpriseClients.length ? <span className="client-count">{enterpriseClients.length}</span>:null}</button>
                </div>
              </div>
          </div>
         <div className="col-lg-12">
         
         {activeTab == 'standard' && <div className="form-group"><Field
                labelledBy={"Select"}
                options={anouncementClientDropdown && anouncementClientDropdown.standard}
                value={standardClients}
                onChange={(v)=>{
                  dispatch(
                    manageClientsActions.setSatndardClients(v)
                  );
                }}
                name="visibility"
                component={CustomMultiSelect}
                
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All clients are selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              /></div> }

          {activeTab == 'enterprise' &&<div className="form-group"><Field
                labelledBy={"Select"}
                options={anouncementClientDropdown && anouncementClientDropdown.enterprise}
                value={enterpriseClients}
                onChange={(v)=>{
                  dispatch(
                    manageClientsActions.setEnterPriseClients(v)
                  );
                }}
                name="visibility"
                component={CustomMultiSelect}
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All clients are selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              /></div> }
          </div>
          <div className="col-lg-12">
          <div className="form-group">
                <label>Message</label>
                <ReactQuill
                  modules={quillOptionsCongif.modules}
                  formats={quillOptionsCongif.formats}
                  value={comment}
                  onChange={(e) => {
                    setComment(e)}}
                  ref={editor}
                />
                
              </div>
          </div>
          <div className="col-lg-6">
          <div className="form-group">
          <label>Start Date</label>
              <DatePicker
                  className="form-control"
                  dateFormat="yyyy/MM/dd"
                  selected={startDate}
                  onChange={(date) => {setStartDate(date);setEndDate("");}}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date()}
                />
            </div>
            </div>
            <div className="col-lg-6">
            <div className="form-group">
            <label>End Date</label>
              <DatePicker
                disabled={startDate? false:true}
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div>
            </div>
          <div className="col-lg-6">
          <label>Status</label>
          <Select
              onChange={changeStatus}
              value={anouncement.is_active}
              name="status"
            >
              <option value={""}>{"Select"}</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </Select>
          </div>
          <div className="col-lg-12">
          <button className="btn btn-blue-primary" onClick={updateAnnouncementData} type="button">Save</button>
          </div>
        </div>
      </Form>
      </Formik>
      </Modal.Body>
      </CardBody>
     
    </Card>
  );
}
