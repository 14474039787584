/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import SVG from "react-inlinesvg";
import './custom.scss';
import {
  sortCaret,
  headerSortingClasses, toAbsoluteUrl
} from "../../../../_metronic/_helpers";

import Table from "../../../components/Table";
import { actions as manageClientsActions } from "../redux/manageClientsRedux";
import { Formik, Form, Field } from "formik";
import { CustomMultiSelect } from "../../../components/CustomMultiSelect";
import DatePicker from "react-datepicker";

function ClientActivityLogs({ clientID, activetab }) {
  const dispatch = useDispatch();
  const [fullName, setFullName] = useState('');
 
  const {
    clientActivityList,
    getClientActivityLoading,
    totalClientActivityRecords,
    currentPageActivity,
    maxActivityPerPage,
    clientsSortFieldActivity,
    clientsSortOrderActivity,
    clientActivityFilter,
    clientActivityUpdatedBy,
    clientActivityFunction,
    clientActivityStartDate,
    clientActivityEndDate,
  } = useSelector((state) => state.manageClients);
  const [cookies] = useCookies(["accessToken"]);

  useEffect(() => {
    if (activetab === "activitylogs") {
      if(currentPageActivity){
        dispatch(
          manageClientsActions.changeCurrentPageActivity(
            clientID,
            0,
            maxActivityPerPage,
            cookies.accessToken
          )
        );
      }else{
        dispatch(
          manageClientsActions.getClientActivityLogsList(
            clientID,
            clientActivityUpdatedBy,
            clientActivityFunction,
            clientActivityStartDate,
            clientActivityEndDate,
            clientsSortFieldActivity,
            clientsSortOrderActivity,
            currentPageActivity,
            maxActivityPerPage,
            cookies.accessToken
          )
        );
      }
    }
  }, [activetab]);


  const paginationOptions = {
    paginationStartIndex: 1,
    custom: true,
    totalSize: totalClientActivityRecords ? totalClientActivityRecords : 0,
    sizePerPage: maxActivityPerPage,
    page: currentPageActivity + 1,
  };

  const handleTableChange = (type, newState) => {
    if (type === "pagination") {
      const { page } = newState;
      if (page !== 0) {
        dispatch(
          manageClientsActions.changeCurrentPageActivity(
            clientID,
            clientActivityUpdatedBy,
            clientActivityFunction,
            clientActivityStartDate,
            clientActivityEndDate,
            page - 1,
            maxActivityPerPage,
            cookies.accessToken
          )
        );
      }
    }

    if (type === "sort") {
      let { sortField, sortOrder } = newState;
      if (
        sortField !== clientsSortFieldActivity ||
        sortOrder !== clientsSortOrderActivity
      ) {
        dispatch(
          manageClientsActions.changeSortActivity(
            clientID,
            clientActivityUpdatedBy,
            clientActivityFunction,
            clientActivityStartDate,
            clientActivityEndDate,
            sortField,
            sortOrder,
            cookies.accessToken
          )
        );
      }
    }
  };

  const tableColumns = [
    {
      dataField: "id",
      text: "Id",
      sort: true,
      formatter: function (cell, row) {
        return row.DT_RowIndex;
      },
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "time",
      text: "Time",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "function",
      text: "Function",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_by",
      text: "Updated By",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "activity",
      text: "Activity",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: function (cell, row) {
        return <div dangerouslySetInnerHTML={{
          __html: cell,
        }}></div>
      },
    },
  ];

  useEffect(() => {
    dispatch(manageClientsActions.getClientActivityFilter(cookies.accessToken));
    return () => {
      dispatch(manageClientsActions.clearClientAccountDetails());
    };
  },[])
  const setSelectedClientActivityUpdatedBy = (val)=>{
    dispatch(
      manageClientsActions.setClientActivityUpdatedBy(val)
    );
  }
  const setSelectedClientActivityFunction = (val)=>{
    dispatch(
      manageClientsActions.setClientActivityFunction(val)
    );
  }
  const setCreatedStartDate = (val)=>{
    dispatch(
      manageClientsActions.setClientActivityStartDate(val)
    );
    if(!val){
      dispatch(
        manageClientsActions.setClientActivityEndDate(null)
      );
    }
  }
  const setCreatedEndDate = (val)=>{
    dispatch(
      manageClientsActions.setClientActivityEndDate(val)
    );
  }
  const filterActivityLogs = ()=>{
    dispatch(
      manageClientsActions.getClientActivityLogsList(
        clientID,
        clientActivityUpdatedBy,
        clientActivityFunction,
        clientActivityStartDate,
        clientActivityEndDate,
        clientsSortFieldActivity,
        clientsSortOrderActivity,
        currentPageActivity,
        maxActivityPerPage,
        cookies.accessToken
      )
    );
  }
  return (
    <>
    <Formik>
    <Form className="form form-label-right">
      <div className="form-group row client-data client-activity">
            <div className="col-lg-3">
              <Field
                labelledBy={"Select"}
                options={clientActivityFilter && clientActivityFilter['updated_by'] ? clientActivityFilter['updated_by']:[]}
                value={clientActivityUpdatedBy}
                onChange={setSelectedClientActivityUpdatedBy}
                name="visibility"
                component={CustomMultiSelect}
                label="Updated By"
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              />
            </div>
            <div className="col-lg-3">
              <Field
                labelledBy={"Select"}
                options={clientActivityFilter && clientActivityFilter['functions'] ? clientActivityFilter['functions']:[]}
                value={clientActivityFunction}
                onChange={setSelectedClientActivityFunction}
                name="visibility"
                component={CustomMultiSelect}
                label="Function"
                overrideStrings={{
                  selectSomeItems: "Select...",
                  allItemsAreSelected: "All selected.",
                  selectAll: "Select All",
                  search: "Search",
                  clearSearch: "Clear Search",
                }}
                closeOnChangedValue={true}
              />
            </div>
            <div className="col-lg-6">
            <label>Date</label>
            <br></br>
            <div className="request-datepicker">
             <DatePicker
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={clientActivityStartDate}
                onChange={(date) => setCreatedStartDate(date)}
                selectsStart
                startDate={clientActivityStartDate}
                endDate={clientActivityEndDate}
                maxDate={new Date()}
              />
              <span>to</span>
              <DatePicker
                disabled={clientActivityStartDate? false:true}
                className="form-control"
                dateFormat="yyyy/MM/dd"
                selected={clientActivityEndDate}
                onChange={(date) => setCreatedEndDate(date)}
                selectsEnd
                startDate={clientActivityStartDate}
                endDate={clientActivityEndDate}
                minDate={clientActivityStartDate}
                maxDate={new Date()}
              />
              </div>
            </div>
            <button type="button" className="btn btn-primary" onClick={filterActivityLogs}>Search</button>
          </div>
      </Form>
      </Formik>
      <Table
        listLoading={getClientActivityLoading}
        entities={clientActivityList ? clientActivityList : []}
        columns={tableColumns}
        paginationOptions={paginationOptions}
        totalCount={totalClientActivityRecords}
        handleTableChange={(type, newState) =>
          handleTableChange(type, newState)
        }
        sortState={{
          clientID: clientID,
          dataField: clientsSortFieldActivity,
          order: clientsSortOrderActivity,
        }}
      />
    </>
  );
}

export default ClientActivityLogs;