/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { Tab, Nav, Col, Row, Modal } from "react-bootstrap";

import {
  Card,
  CardBody,
  CardHeader,
  // CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import General from "../components/GeneralConfig/General";
import Images from "../components/GeneralConfig/Images";
import Footer from "../components/GeneralConfig/Footer";
import Colors from "../components/GeneralConfig/Colors";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { actions as generalConfigsActions } from "../redux/generalConfigsRedux";

 const GeneralConfig = () => {
  const dispatch = useDispatch();
  const {configs=[]} = useSelector((state) => state.genConfigs);
  const [cookies] = useCookies(["accessToken"]);
  const [activeTab, setActiveTab] = useState("general");
  
  useEffect(()=>{
    dispatch(generalConfigsActions.getGeneralConfigs(cookies.accessToken))
  },[cookies.accessToken])
  
  const tabSelect = (k) => {
    if(k == activeTab){
      return;
    }
    setActiveTab(k)
  }

  const saveConfigs =(data)=>{
    console.log(data);
    dispatch(generalConfigsActions.saveGeneralConfigs(data, cookies.accessToken))
  }

  return (
    <Card>
      <CardHeader title={"General Configuration"}></CardHeader>
      <CardBody>
        <Modal.Body
          style={{ padding: 0 }}
          className="overlay overlay-block cursor-default"
        >
        {configs.length === 0 ? (
          <div
            style={{ zIndex: 9999999 }}
            className="overlay-layer bg-transparent"
          >
            <div className="spinner spinner-lg spinner-success" />
          </div>
          )
          :
          <Tab.Container
            onSelect={tabSelect}
            id="left-tabs-example"
            defaultActiveKey="cabinet-brand"
            activeKey={activeTab}
          >
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="general">General</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="images">Images</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="footer">Footer</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="colors">Colors</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="general">
                    <General {...{allConfigs:configs,saveConfigs}} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="images">
                    <Images {...{allConfigs:configs,saveConfigs}}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="footer">
                    <Footer {...{allConfigs:configs,saveConfigs}}/>
                  </Tab.Pane>
                  <Tab.Pane eventKey="colors">
                    <Colors {...{allConfigs:configs,saveConfigs}}/>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        }
        </Modal.Body>
      </CardBody>
    </Card>
  );
}

export default memo(GeneralConfig)