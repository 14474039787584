import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import {
  getApiToken,
  postApiToken,
  putApiToken,
  deleteApiToken,
} from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";
import { forEach } from "lodash-es";

const uid = new ShortUniqueId();

export const actionTypes = {
  SET_CATEGORY_LIST_LOADING: "SET_CATEGORY_LIST_LOADING",
  GET_CATEGORY_LIST: "GET_CATEGORY_LIST",
  SET_CATEGORY_LIST: "SET_CATEGORY_LIST",
  CHANGE_CURRENT_PAGE_CATEGORY: "CHANGE_CURRENT_PAGE_CATEGORY",
  CLEAR_LIST_CATEGORY: "CLEAR_LIST_CATEGORY",
  GET_CLIENTS_DROPDOWN: "GET_CLIENTS_DROPDOWN",
  SET_CLIENTS_DROPDOWN: "SET_CLIENTS_DROPDOWN",
  SET_CLIENTS_DROPDOWN_LOADING: "SET_CLIENTS_DROPDOWN_LOADING",
  SET_CLIENTS_DROPDOWN_FAILED: "SET_CLIENTS_DROPDOWN_FAILED",
  CHANGE_CATEGORY_DATA: "CHANGE_CATEGORY_DATA",
  CLEAR_CATEGORY_DATA: "CLEAR_CATEGORY_DATA",
  SAVE_CATEGORY_DATA: "SAVE_CATEGORY_DATA",
  SAVE_CATEGORY_DATA_LOADING: "SAVE_CATEGORY_DATA_LOADING",
  SAVE_CATEGORY_DATA_FAILED: "SAVE_CATEGORY_DATA_FAILED",
  SAVE_CATEGORY_DATA_SUCCESS: "SAVE_CATEGORY_DATA_SUCCESS",
  CLOSE_CATEGORY_DIALOG: "CLOSE_CATEGORY_DIALOG",
  GET_CATEGORY_INDIVIDUAL_DATA: "GET_CATEGORY_INDIVIDUAL_DATA",
  GET_CATEGORY_INDIVIDUAL_DATA_LOADING: "GET_CATEGORY_INDIVIDUAL_DATA_LOADING",
  GET_CATEGORY_INDIVIDUAL_DATA_FAILED: "GET_CATEGORY_INDIVIDUAL_DATA_FAILED",
  GET_CATEGORY_INDIVIDUAL_DATA_SUCCESS: "GET_CATEGORY_INDIVIDUAL_DATA_SUCCESS",
  UPDATE_CATEGORY_INDIVIDUAL_DATA: "UPDATE_CATEGORY_INDIVIDUAL_DATA",
  UPDATE_CATEGORY_INDIVIDUAL_DATA_LOADING:
    "UPDATE_CATEGORY_INDIVIDUAL_DATA_LOADING",
  UPDATE_CATEGORY_INDIVIDUAL_DATA_SUCCESS:
    "UPDATE_CATEGORY_INDIVIDUAL_DATA_SUCCESS",
  UPDATE_CATEGORY_INDIVIDUAL_DATA_FAILED:
    "UPDATE_CATEGORY_INDIVIDUAL_DATA_FAILED",
  CHANGE_SORT_CATEGORY: "CHANGE_SORT_CATEGORY",
  CHANGE_CATEGORY_FILTER_DATA: "CHANGE_CATEGORY_FILTER_DATA",
  CLEAR_CATEGORY_FILTER_DATA: "CLEAR_CATEGORY_FILTER_DATA",
  CLEAR_ALL_CATEGORY: "CLEAR_ALL_CATEGORY",
  SET_INTAKE_TYPE: "SET_INTAKE_TYPE",
  SET_SELECTED_CLIENTS: "SET_SELECTED_CLIENTS",
  SET_CATEGORY_ID: "SET_CATEGORY_ID",
  SELECT_ALL_CLIENTS: "SELECT_ALL_CLIENTS",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_DATA_LOADING: "GET_CATEGORIES_DATA_LOADING",
  SET_CATEGORIES: "SET_CATEGORIES",
  DELETE_CATEGORY_OPTION: "DELETE_CATEGORY_OPTION",
  GET_CABINET_BRAND_OPTION: "GET_CABINET_BRAND_OPTION",
  SET_CABINET_BRAND_OPTION: "SET_CABINET_BRAND_OPTION",
  GET_CABINET_BRAND_OPTION_LOADING: "GET_CABINET_BRAND_OPTION_LOADING",
  SET_FILTER: "SET_FILTER_INTAKE",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE_INTAKE",
  GET_APPLIANCE_OPTIONS: "GET_APPLIANCE_OPTIONS",
  SET_APPLIANCE_OPTION: "SET_APPLIANCE_OPTION",
  INTAKE_LOADING: "INTAKE_LOADING",
  INTAKE_FAIL: "INTAKE_FAIL",
  INTAKE_SUCCESS: "INTAKE_SUCCESS",
  SET_DEFAULT_CLIENT: "SET_DEFAULT_CLIENT",
  SET_CABINET_BRAND_OPTION_FILTER: "SET_CABINET_BRAND_OPTION_FILTER",
  SET_APPLIANCE_OPTION_FILTER: "SET_APPLIANCE_OPTION_FILTER",
  SET_DEFAULT_FILTER: "SET_DEFAULT_FILTER",
  SET_CLIENT_OPTION_FILTER: "SET_CLIENT_OPTION_FILTER",
  SET_STANDARD_CLIENTS: "SET_STANDARD_CLIENTS",
  SET_ENTERPRISE_CLIENTS: "SET_ENTERPRISE_CLIENTS"
};

const initialCategoryState = {
  categories: [],
  categoryId: 1,
  categoryList: undefined,
  totalCategoryRecords: undefined,
  listLoading: false,
  currentPage: 0,
  maxCategoryPerPage: 10,
  categorySortField: "id",
  categorySortOrder: "desc",
  categoryFilterData: {
    name: "",
  },
  categoryData: {
    name: "",
    visibility: "",
    cabinet_brand_id: "",
    option_type: 1,
    appliance_id:"",
  },
  clients: [],
  selectedClients: [],
  clientsDropdownLoading: false,
  clientsDropdownFailed: false,
  saveCategoryDataLoading: false,
  saveCategoryDataSuccess: false,
  saveCategoryDataFailed: false,
  updateCategorySuccess: false,
  updateCategoryLoading: false,
  updateCategoryFailed: false,
  intakeType: "cabinet-brand",
  selectAllClients: false,
  getCategoriesDataLoading: false,
  cabinateBrandOption: [],
  getCabinateBrandOptionLoading: false,
  isfilter: false,
  applianceOption: [],
  intakeLoading: false,
  intakeSuccess: false,
  intakeFail: false,
  isDefaultCient: false,
  cabinateBrandOptionForFilter: [],
  applianceOptionForFilter: [],
  cabinateSeletedFilter:[],
  applianceSelectedFilter:[],
  defaultFilter: "",
  clientsOptionForFilter:[],
  clientsSelectedFilter:[],
  standardClients:[],
  enterpriseClients:[],
};

export const reducer = (state = initialCategoryState, action) => {
  switch (action.type) {
    case actionTypes.SET_CABINET_BRAND_OPTION: {
      const { cabinateBrandOptionData } = action.payload;
      let cabinateBrandData = [];
      let cabinateBrandDataFilter = [];
      for (var key of Object.keys(cabinateBrandOptionData)) {
        let cabinateBrand = {
          id: key,
          name: cabinateBrandOptionData[key],
        };
        let cabinateBrandFilter = {
          value: key,
          label: cabinateBrandOptionData[key],
        };
        cabinateBrandData.push(cabinateBrand);
        cabinateBrandDataFilter.push(cabinateBrandFilter);
      }
      return {
        ...state,
        cabinateBrandOption:
          cabinateBrandData && cabinateBrandData.length !== 0
            ? cabinateBrandData
            : undefined,
        cabinateBrandOptionForFilter:
          cabinateBrandDataFilter && cabinateBrandDataFilter.length !== 0
            ? cabinateBrandDataFilter
            : undefined,    
      };
    }

    case actionTypes.SET_FILTER: {
      const { flag } = action.payload;
      return {
        ...state,
        isfilter: flag,
      };
    }
    case actionTypes.SET_CURRENT_PAGE: {
      const { data } = action.payload;
      return {
        ...state,
        currentPage: data,
      };
    }

    case actionTypes.GET_CABINET_BRAND_OPTION_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getCabinateBrandOptionLoading: flag,
      };
    }

    case actionTypes.SET_CATEGORIES: {
      const { categories } = action.payload;
      return {
        ...state,
        categories: categories,
      };
    }

    case actionTypes.SELECT_ALL_CLIENTS: {
      const { selectAllClients } = action.payload;
      return {
        ...state,
        selectAllClients: selectAllClients,
      };
    }

    case actionTypes.GET_CATEGORIES_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getCategoriesDataLoading: flag,
      };
    }

    case actionTypes.SET_CATEGORY_ID: {
      const { categoryId } = action.payload;
      return {
        ...state,
        categoryId: categoryId,
      };
    }

    case actionTypes.SET_SELECTED_CLIENTS: {
      const { selectedClients } = action.payload;
      return {
        ...state,
        selectedClients: selectedClients,
      };
    }

    case actionTypes.SET_INTAKE_TYPE: {
      const { intakeType } = action.payload;
      return {
        ...state,
        intakeType: intakeType,
      };
    }
    case actionTypes.SET_CATEGORY_LIST_LOADING: {
      return {
        ...state,
        listLoading: action.payload.flag,
      };
    }

    case actionTypes.CLEAR_LIST_CATEGORY: {
      return {
        ...state,
        totalCategoryRecords: undefined,
        categoryList: undefined,
      };
    }

    case actionTypes.SET_CATEGORY_LIST: {
      const { categoryData } = action.payload;
      const totalCategoryRecords =
        state.isfilter && categoryData.recordsTotal
          ? categoryData.recordsFiltered
          : categoryData.recordsTotal;
      const categoryList = categoryData.data ? categoryData.data : undefined;
      return {
        ...state,
        totalCategoryRecords,
        categoryList,
      };
    }

    case actionTypes.CHANGE_CURRENT_PAGE_CATEGORY: {
      const { page } = action.payload;
      return {
        ...state,
        currentPage: page,
      };
    }

    case actionTypes.CHANGE_SORT_CATEGORY: {
      const { field, order } = action.payload;
      return {
        ...state,
        categorySortField: field,
        categorySortOrder: order,
      };
    }

    case actionTypes.CHANGE_CATEGORY_FILTER_DATA: {
      const { categoryFilterData } = action.payload;
      return {
        ...state,
        categoryFilterData: categoryFilterData,
      };
    }

    case actionTypes.SET_CLIENTS_DROPDOWN: {
      const { data } = action.payload;
      let clientsDataEnterPrise = [];
      let clientsDataStandard = [];
      let clientsDataFilter = [];
      data.clients.enterprise.forEach(function(c){
        clientsDataEnterPrise.push({label: c.company_name, value: c.id})
      });
      data.clients.standard.forEach(function(c){
        clientsDataStandard.push({label: c.company_name, value: c.id})
      });
      clientsDataFilter = clientsDataEnterPrise.concat(clientsDataStandard);
      
      return {
        ...state,
        clients:{standard:clientsDataStandard,enterprise:clientsDataEnterPrise},
        clientsOptionForFilter: clientsDataFilter && clientsDataFilter.length !== 0 ? clientsDataFilter : undefined,
      };
    }

    case actionTypes.SET_CLIENTS_DROPDOWN_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        clientsDropdownLoading: flag,
      };
    }

    case actionTypes.SET_CLIENTS_DROPDOWN_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        clientsDropdownFailed: flag,
      };
    }

    case actionTypes.CHANGE_CATEGORY_DATA: {
      const { categoryData } = action.payload;

      return {
        ...state,
        categoryData: {
          name: categoryData.name ? categoryData.name : "",
          cabinet_brand_id: categoryData.cabinet_brand_id
            ? categoryData.cabinet_brand_id
            : "",
          option_type: categoryData.option_type
          ? categoryData.option_type
          : 1,
          appliance_id: categoryData.appliance_id ? categoryData.appliance_id : "",
        },
      };
    }

    case actionTypes.CLEAR_CATEGORY_DATA: {
      return {
        ...state,
        categoryData: {
          name: "",
          visibility: "",
        },
        isDefaultCient: false,
      };
    }

    case actionTypes.SAVE_CATEGORY_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        saveCategoryDataLoading: flag,
      };
    }

    case actionTypes.SAVE_CATEGORY_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        saveCategoryDataFailed: flag,
      };
    }

    case actionTypes.SAVE_CATEGORY_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        saveCategoryDataSuccess: flag,
      };
    }

    case actionTypes.CLOSE_CATEGORY_DIALOG: {
      return {
        ...state,
        categoryData: {
          name: "",
          visibility: "",
        },
        clients: [],
        selectedClients: [],
        clientsDropdownLoading: false,
        clientsDropdownFailed: false,
        saveCategoryDataLoading: false,
        saveCategoryDataSuccess: false,
        saveCategoryDataFailed: false,
        updateCategoryFailed: false,
        updateCategoryLoading: false,
        updateCategorySuccess: false,
        standardClients: [],
        enterpriseClients: [],
      };
    }

    case actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        updateCategorySuccess: flag,
      };
    }

    case actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_FAILED: {
      const { flag } = action.payload;
      return {
        ...state,
        updateCategoryFailed: flag,
      };
    }

    case actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        updateCategoryLoading: flag,
      };
    }

    case actionTypes.CLEAR_ALL_CATEGORY: {
      return {
        categoryList: undefined,
        totalCategoryRecords: undefined,
        listLoading: false,
        currentPage: 0,
        maxCategoryPerPage: 10,
        categorySortField: "id",
        categorySortOrder: "desc",
        categoryFilterData: {
          name: "",
        },
        categoryData: {
          name: "",
          visibility: "",
        },
        clients: [],
        selectedClients: [],
        clientsDropdownLoading: false,
        clientsDropdownFailed: false,
        saveCategoryDataLoading: false,
        saveCategoryDataSuccess: false,
        saveCategoryDataFailed: false,
        updateCategorySuccess: false,
        updateCategoryLoading: false,
        updateCategoryFailed: false,
      };
    }

    case actionTypes.SET_APPLIANCE_OPTION: {
      const { options } = action.payload;
      let applianceOptionData = [];
      let applianceOptionDataFilter = [];
      options.data.forEach(function(v,i){
        let appliances = {
          id: v.id,
          name: v.name,
        };
        let appliancesFilter = {
          value: v.id,
          label: v.name,
        };
        applianceOptionData.push(appliances);
        applianceOptionDataFilter.push(appliancesFilter);
      });
        
      return {
        ...state,
        applianceOption:
          applianceOptionData && applianceOptionData.length !== 0
            ? applianceOptionData
            : undefined,
        applianceOptionForFilter:
          applianceOptionDataFilter && applianceOptionDataFilter.length !== 0
            ? applianceOptionDataFilter
            : undefined,
      };
    }

    case actionTypes.INTAKE_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        intakeLoading: flag,
      };
    }

    case actionTypes.INTAKE_SUCCESS: {
      const { flag } = action.payload;
      return {
        ...state,
        intakeSuccess: flag,
      };
    }

    case actionTypes.INTAKE_FAIL: {
      const { flag } = action.payload;
      return {
        ...state,
        intakeFail: flag,
      };
    }

    case actionTypes.SET_DEFAULT_CLIENT: {
      const { data } = action.payload;
      return {
        ...state,
        isDefaultCient: data,
      };
    }

    case actionTypes.SET_CABINET_BRAND_OPTION_FILTER: {
      const { cabinateSeletedFilter } = action.payload;
      return {
        ...state,
        cabinateSeletedFilter: cabinateSeletedFilter,
      };
    }

    case actionTypes.SET_APPLIANCE_OPTION_FILTER: {
      const { applianceSelectedFilter } = action.payload;
      return {
        ...state,
        applianceSelectedFilter: applianceSelectedFilter,
      };
    }

    case actionTypes.SET_DEFAULT_FILTER: {
      const { data } = action.payload;
      return {
        ...state,
        defaultFilter: data,
      };
    }

    case actionTypes.SET_CLIENT_OPTION_FILTER: {
      const { clientsSelectedFilter} = action.payload;
      return {
        ...state,
        clientsSelectedFilter: clientsSelectedFilter,
      };
    }

    case actionTypes.SET_ENTERPRISE_CLIENTS: {
      const { data } = action.payload;
      return {
        ...state,
        enterpriseClients: data,
      };
    }

    case actionTypes.SET_STANDARD_CLIENTS: {
      const { data } = action.payload;
      return {
        ...state,
        standardClients: data,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  setCurrentPage: (data) => ({
    type: actionTypes.SET_CURRENT_PAGE,
    payload: { data },
  }),
  setFilter: (flag) => ({
    type: actionTypes.SET_FILTER,
    payload: { flag },
  }),
  getCabinateBrandOption: (accessToken) => ({
    type: actionTypes.GET_CABINET_BRAND_OPTION,
    payload: { accessToken },
  }),
  setCabinateBrandOption: (cabinateBrandOptionData) => ({
    type: actionTypes.SET_CABINET_BRAND_OPTION,
    payload: { cabinateBrandOptionData },
  }),
  getCabinateBrandOptionLoading: (flag) => ({
    type: actionTypes.GET_CABINET_BRAND_OPTION_LOADING,
    payload: { flag },
  }),
  deleteCategoryOption: (id, accessToken) => ({
    type: actionTypes.DELETE_CATEGORY_OPTION,
    payload: { id, accessToken },
  }),
  getCategories: (accessToken) => ({
    type: actionTypes.GET_CATEGORIES,
    payload: { accessToken },
  }),
  setCategories: (categories) => ({
    type: actionTypes.SET_CATEGORIES,
    payload: { categories },
  }),
  getCategoriesDataLoading: (flag) => ({
    type: actionTypes.GET_CATEGORIES_DATA_LOADING,
    payload: { flag },
  }),
  setSelectAllClients: (selectAllClients) => ({
    type: actionTypes.SELECT_ALL_CLIENTS,
    payload: { selectAllClients },
  }),
  setCategoryId: (categoryId) => ({
    type: actionTypes.SET_CATEGORY_ID,
    payload: { categoryId },
  }),
  setSelectedClients: (selectedClients) => ({
    type: actionTypes.SET_SELECTED_CLIENTS,
    payload: { selectedClients },
  }),
  setIntakeType: (intakeType) => ({
    type: actionTypes.SET_INTAKE_TYPE,
    payload: { intakeType },
  }),
  setListLoading: (flag) => ({
    type: actionTypes.SET_CATEGORY_LIST_LOADING,
    payload: { flag },
  }),
  getCategoryList: (
    sortField,
    sortOrder,
    page,
    length,
    accessToken,
    intakeTypeSlug
  ) => ({
    type: actionTypes.GET_CATEGORY_LIST,
    payload: {
      page,
      length,
      accessToken,
      sortField,
      sortOrder,
      intakeTypeSlug,
    },
  }),
  setCategoryList: (categoryData) => ({
    type: actionTypes.SET_CATEGORY_LIST,
    payload: { categoryData },
  }),
  changeCurrentPage: (page, length, accessToken) => ({
    type: actionTypes.CHANGE_CURRENT_PAGE_CATEGORY,
    payload: { page, accessToken, length },
  }),
  clearList: () => ({ type: actionTypes.CLEAR_LIST_CATEGORY }),
  changeSort: (field, order, accessToken) => ({
    type: actionTypes.CHANGE_SORT_CATEGORY,
    payload: { field, order, accessToken },
  }),
  changeCategoryFilterData: (categoryFilterData) => ({
    type: actionTypes.CHANGE_CATEGORY_FILTER_DATA,
    payload: { categoryFilterData },
  }),
  clearCategoryFilterData: (accessToken, tabChange) => ({
    type: actionTypes.CLEAR_CATEGORY_FILTER_DATA,
    payload: { accessToken, tabChange },
  }),
  getClientsDropdown: (accessToken) => ({
    type: actionTypes.GET_CLIENTS_DROPDOWN,
    payload: { accessToken },
  }),
  setClientsDropdown: (data) => ({
    type: actionTypes.SET_CLIENTS_DROPDOWN,
    payload: { data },
  }),
  setClientsDropdownLoading: (flag) => ({
    type: actionTypes.SET_CLIENTS_DROPDOWN_LOADING,
    payload: { flag },
  }),
  setClientsDropdownFailed: (flag) => ({
    type: actionTypes.SET_CLIENTS_DROPDOWN_FAILED,
    payload: { flag },
  }),
  changeCategoryData: (categoryData) => ({
    type: actionTypes.CHANGE_CATEGORY_DATA,
    payload: { categoryData },
  }),
  clearCategoryData: () => ({ type: actionTypes.CLEAR_CATEGORY_DATA }),
  saveCategory: (categoryData, accessToken, selectAll) => ({
    type: actionTypes.SAVE_CATEGORY_DATA,
    payload: { categoryData, accessToken, selectAll },
  }),
  saveCategoryDataLoading: (flag) => ({
    type: actionTypes.SAVE_CATEGORY_DATA_LOADING,
    payload: { flag },
  }),
  saveCategoryDataFailed: (flag) => ({
    type: actionTypes.SAVE_CATEGORY_DATA_FAILED,
    payload: { flag },
  }),
  saveCategoryDataSuccess: (flag) => ({
    type: actionTypes.SAVE_CATEGORY_DATA_SUCCESS,
    payload: { flag },
  }),
  closeCategoryDialog: () => ({ type: actionTypes.CLOSE_CATEGORY_DIALOG }),
  getCategory: (id, accessToken) => ({
    type: actionTypes.GET_CATEGORY_INDIVIDUAL_DATA,
    payload: { id, accessToken },
  }),
  getCategoryLoading: (flag) => ({
    type: actionTypes.GET_CATEGORY_INDIVIDUAL_DATA_LOADING,
    payload: { flag },
  }),
  getCategorySuccess: (flag) => ({
    type: actionTypes.GET_CATEGORY_INDIVIDUAL_DATA_SUCCESS,
    payload: { flag },
  }),
  getCategoryFailed: (flag) => ({
    type: actionTypes.GET_CATEGORY_INDIVIDUAL_DATA_FAILED,
    payload: { flag },
  }),
  updateCategory: (id, categoryData, accessToken, selectAll) => ({
    type: actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA,
    payload: { id, categoryData, accessToken, selectAll },
  }),
  updateCategorySuccess: (flag) => ({
    type: actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_SUCCESS,
    payload: { flag },
  }),
  updateCategoryLoading: (flag) => ({
    type: actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_LOADING,
    payload: { flag },
  }),
  updateCategoryFailed: (flag) => ({
    type: actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA_FAILED,
    payload: { flag },
  }),
  clearAll: () => ({ type: actionTypes.CLEAR_ALL_CATEGORY }),
  getApplianceOptions: (accessToken) => ({
    type: actionTypes.GET_APPLIANCE_OPTIONS,
    payload: { accessToken },
  }),
  setApplianceOption: (options) => ({
    type: actionTypes.SET_APPLIANCE_OPTION,
    payload: { options },
  }),
  setIntakeLoading: (flag) => ({
    type: actionTypes.INTAKE_LOADING,
    payload: { flag },
  }),
  setIntakeSuccess: (flag) => ({
    type: actionTypes.INTAKE_SUCCESS,
    payload: { flag },
  }),
  setIntakeFail: (flag) => ({
    type: actionTypes.INTAKE_FAIL,
    payload: { flag },
  }),
  setDefaultClient: (data) => ({
    type: actionTypes.SET_DEFAULT_CLIENT,
    payload: { data },
  }),
  setSelectedApplianceFilter: (applianceSelectedFilter) => ({
    type: actionTypes.SET_APPLIANCE_OPTION_FILTER,
    payload: { applianceSelectedFilter },
  }),
  setSelectedCabinateFilter: (cabinateSeletedFilter) => ({
    type: actionTypes.SET_CABINET_BRAND_OPTION_FILTER,
    payload: { cabinateSeletedFilter },
  }),
  setDefaultFilter: (data) => ({
    type: actionTypes.SET_DEFAULT_FILTER,
    payload: { data },
  }),
  setSelectedClientFilter: (clientsSelectedFilter) => ({
    type: actionTypes.SET_CLIENT_OPTION_FILTER,
    payload: { clientsSelectedFilter },
  }),
  setStandardClients: (data) => ({
    type: actionTypes.SET_STANDARD_CLIENTS,
    payload: { data },
  }),
  setEnterPriseClients: (data) => ({
    type: actionTypes.SET_ENTERPRISE_CLIENTS,
    payload: { data },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.CHANGE_CURRENT_PAGE_CATEGORY,
    function* changePageSaga(action) {
      const { categorySortField, categorySortOrder, intakeType } = yield select(
        (state) => state.intakeForm
      );
      const { page, length, accessToken } = action.payload;
      yield put(
        actions.getCategoryList(
          categorySortField,
          categorySortOrder,
          page,
          length,
          accessToken,
          intakeType
        )
      );
    }
  );

  yield takeLatest(actionTypes.CHANGE_SORT_CATEGORY, function* changeSortSaga(
    action
  ) {
    let { field, order, accessToken } = action.payload;
    let { currentPage, maxCategoryPerPage, intakeType } = yield select(
      (state) => state.intakeForm
    );
    yield put(
      actions.getCategoryList(
        field,
        order,
        currentPage,
        maxCategoryPerPage,
        accessToken,
        intakeType
      )
    );
  });

  yield takeLatest(
    actionTypes.CLEAR_CATEGORY_FILTER_DATA,
    function* clearCategoryFilterDataSaga(action) {
      let { accessToken, tabChange } = action.payload;
      let {
        categorySortField,
        categorySortOrder,
        currentPage,
        maxCategoryPerPage,
        intakeType,
      } = yield select((state) => state.intakeForm);
      let categoryFilterData = {
        name: "",
      };
      yield put(actions.setSelectedClientFilter([]));
      yield put(actions.setSelectedApplianceFilter([]));
      yield put(actions.setSelectedCabinateFilter([]));
      yield put(actions.setDefaultFilter(""));
      yield put(actions.changeCategoryFilterData(categoryFilterData));
      if (tabChange) {
      } else {
        yield put(
          actions.getCategoryList(
            categorySortField,
            categorySortOrder,
            currentPage,
            maxCategoryPerPage,
            accessToken,
            intakeType
          )
        );
      }
    }
  );

  yield takeLatest(actionTypes.GET_CATEGORY_LIST, getCategoryListSaga);

  yield takeLatest(actionTypes.GET_CLIENTS_DROPDOWN, getClientsListSaga);

  yield takeLatest(actionTypes.SAVE_CATEGORY_DATA, saveCategorySaga);
  yield takeLatest(
    actionTypes.GET_CABINET_BRAND_OPTION,
    getCabinateBrandOptionSaga
  );

  yield takeLatest(actionTypes.GET_CATEGORY_INDIVIDUAL_DATA, getCategorySaga);
  yield takeLatest(actionTypes.GET_CATEGORIES, getCategoriesSaga);
  yield takeLatest(
    actionTypes.DELETE_CATEGORY_OPTION,
    deleteCategoryOptionSaga
  );

  yield takeLatest(
    actionTypes.UPDATE_CATEGORY_INDIVIDUAL_DATA,
    updateCategorySaga
  );

  yield takeLatest(
    actionTypes.GET_APPLIANCE_OPTIONS,
    getapplianceOptionSaga
  );
}

function* getCategoriesSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.getCategoriesDataLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_CATEGORIES_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setCategories(responseData.data.dataTable.original.data)),
          put(actions.getCategoriesDataLoading(false)),
        ]);
      } else {
        yield all([put(actions.getCategoriesDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getCategoriesDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Intake Form Categories",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.setClientsDropdownLoading(false)),
        put(actions.setClientsDropdownFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Categories",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setClientsDropdownLoading(false)),
      put(actions.setClientsDropdownFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Intake Form Categories",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getCategoryListSaga(action) {
  let {
    page,
    length,
    accessToken,
    sortField,
    sortOrder,
    intakeTypeSlug,
  } = action.payload;
  yield put(actions.setListLoading(true));
  const { categoryFilterData, intakeType, selectedClients, applianceSelectedFilter, cabinateSeletedFilter, defaultFilter, clientsSelectedFilter } = yield select(
    (state) => state.intakeForm
  );
  let tempArr = [];
  clientsSelectedFilter.forEach(function(item) {
    tempArr.push(item.value);
  });
  tempArr = tempArr.join();
  let tempArrAppliances = [];
  applianceSelectedFilter.forEach(function(item) {
    tempArrAppliances.push(item.value);
  });
  tempArrAppliances = tempArrAppliances.join();
  let tempArrCabinate = [];
  cabinateSeletedFilter.forEach(function(item) {
    tempArrCabinate.push(item.value);
  });
  tempArrCabinate = tempArrCabinate.join();
  let filterParams = {
    start: page * length,
    length: length,
    intake_type: intakeTypeSlug,
    client_id: tempArr,
    cabinet_brand_id: tempArrCabinate,
    loose_appliance_id: tempArrAppliances,
  };

  if(defaultFilter !== ""){
    filterParams['is_default'] = defaultFilter
  }

  const sortParams = `&columns[0][data]=name&columns[0][name]=&columns[0][searchable]=true&columns[0][orderable]=false&columns[0][search][value]=${categoryFilterData.name}&columns[0][search][regex]=false&columns[1][data]= ${sortField}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${sortOrder}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_CATEGORY_LIST_URL"),
      `${queryString.stringify(filterParams)}${sortParams}`,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.dataTable) {
        yield all([
          put(actions.setCategoryList(responseData.data.dataTable)),
          put(actions.setListLoading(false)),
        ]);
      } else {
        yield all([
          put(actions.clearList()),
          put(actions.setListLoading(false)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield put(actions.setListLoading(false));
        yield put(
          generalActions.addToast(
            "Intake Form Category List",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield put(actions.setListLoading(false));
      yield put(
        generalActions.addToast(
          "Intake Form Category List",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield put(actions.setListLoading(false));
    yield put(
      generalActions.addToast(
        "Intake Form Category List",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getClientsListSaga(action) {
  let { accessToken } = action.payload;
  yield all([
    put(actions.setClientsDropdownLoading(true)),
    put(actions.setClientsDropdownFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_CLIENTS_DROPDOWN_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setClientsDropdown(responseData.data)),
          put(actions.setClientsDropdownLoading(false)),
          put(actions.setClientsDropdownFailed(false)),
        ]);
      } else {
        yield all([
          put(actions.setClientsDropdownLoading(false)),
          put(actions.setClientsDropdownFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.setClientsDropdownLoading(false)),
          put(actions.setClientsDropdownFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Clients Dropdown",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.setClientsDropdownLoading(false)),
        put(actions.setClientsDropdownFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Clients Dropdown",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.setClientsDropdownLoading(false)),
      put(actions.setClientsDropdownFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Clients Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* saveCategorySaga(action) {
  let { categoryData, accessToken, selectAll } = action.payload;
  const { selectedClients, categoryId, intakeType, isDefaultCient, standardClients, enterpriseClients } = yield select(
    (state) => state.intakeForm
  );
  let tempArr = [];
  standardClients.forEach(function(item) {
    tempArr.push(item.value);
  });
  enterpriseClients.forEach(function(item) {
    tempArr.push(item.value);
  });
  tempArr = tempArr.join();
  let categoryDatai = queryString.stringify({
    client_id: tempArr,
    name: categoryData.name,
    category_id: categoryId,
    available_for_all: selectAll,
    cabinet_brand_id: categoryData.cabinet_brand_id,
    status: 1,
    option_type: categoryData.option_type ? categoryData.option_type:null,
    appliance_id: categoryData.appliance_id ? categoryData.appliance_id:"",
    assigned_to_all_client: isDefaultCient?1:0,
  });
  yield all([
    put(actions.saveCategoryDataLoading(true)),
    put(actions.saveCategoryDataSuccess(false)),
    put(actions.saveCategoryDataFailed(false)),
  ]);
  try {
    const response = yield call(
      postApiToken,
      apiUrl("SAVE_CATEGORY_DATA_URL"),
      accessToken,
      categoryDatai
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxCategoryPerPage,
        categorySortField,
        categorySortOrder,
        intakeType,
      } = yield select((state) => state.intakeForm);
      yield all([
        put(
          actions.getCategoryList(
            categorySortField,
            categorySortOrder,
            currentPage,
            maxCategoryPerPage,
            accessToken,
            intakeType
          )
        ),
        put(actions.saveCategoryDataLoading(false)),
        put(actions.saveCategoryDataSuccess(true)),
        put(actions.saveCategoryDataFailed(false)),
        put(actions.clearCategoryData()),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Save Category Option",
          "Category option saved successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.saveCategoryDataLoading(false)),
          put(actions.saveCategoryDataSuccess(false)),
          put(actions.saveCategoryDataFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Intake Form Save Category Option",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.saveCategoryDataLoading(false)),
        put(actions.saveCategoryDataSuccess(false)),
        put(actions.saveCategoryDataFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Save Category Option",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.saveCategoryDataLoading(false)),
      put(actions.saveCategoryDataSuccess(false)),
      put(actions.saveCategoryDataFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Intake Form Save Category Option",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getCategorySaga(action) {
  let { id, accessToken } = action.payload;
  yield all([
    put(actions.getCategoryLoading(true)),
    put(actions.getCategorySuccess(false)),
    put(actions.getCategoryFailed(false)),
  ]);
  try {
    const response = yield call(
      getApiToken,
      `${apiUrl("EDIT_CATEGORY_DATA_URL")}/${id}`,
      null,
      accessToken
    );
    if (response.status === 200) {
      const responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        let temp = {
          name: responseData.data.category_option.category_option_name,
          cabinet_brand_id:
            responseData.data.category_option_cabinet_brand.cabinet_brand_id,
          visibility: "",
          option_type: responseData.data.category_option.option_type,
          appliance_id: responseData.data.category_option_cabinet_brand.cabinet_brand_id,
        };
        if (responseData.data.category_option.available_for_all) {
          let clientsDataEnterPrise = [];
          let clientsDataStandard = [];
          // for (var key of Object.keys(responseData.data.client)) {
          //   let client = {
          //     value: key,
          //     label: responseData.data.client[key],
          //   };
          //   clientsData.push(client);
          // }
          responseData.data.clients.enterprise.forEach(function(c){
            clientsDataEnterPrise.push({label: c.company_name, value: c.id})
          });
          responseData.data.clients.standard.forEach(function(c){
            clientsDataStandard.push({label: c.company_name, value: c.id})
          });
          // yield all([put(actions.setSelectedClients(clientsData))]);
          yield all([put(actions.setEnterPriseClients(clientsDataEnterPrise)),
            put(actions.setStandardClients(clientsDataStandard))
          ]);
        } else {
          let clientsDataEnterPrise = [];
          let clientsDataStandard = [];
          let tempi = responseData.data.selected_client.client_id;
          tempi = tempi.split(",");
          responseData.data.clients.enterprise.forEach(function(c){
            for (var i = 0; i < tempi.length; i++) {
              if (c.id == tempi[i]) {
                let client = {
                  value: c.id,
                  label: c.company_name,
                };
                clientsDataEnterPrise.push(client);
              }
            }
          });
          responseData.data.clients.standard.forEach(function(c){
            for (var i = 0; i < tempi.length; i++) {
              if (c.id == tempi[i]) {
                let client = {
                  value: c.id,
                  label: c.company_name,
                };
                clientsDataStandard.push(client);
              }
            }
          });
          // yield all([put(actions.setSelectedClients(clientsData))]);
          yield all([put(actions.setEnterPriseClients(clientsDataEnterPrise)),
            put(actions.setStandardClients(clientsDataStandard))
          ]);
        }
        let isDefaultCient = Number(responseData.data.category_option.assigned_to_all_client) ? true:false;
        yield all([
          put(actions.changeCategoryData(temp)),
          put(actions.getCategoryLoading(false)),
          put(actions.getCategorySuccess(true)),
          put(actions.getCategoryFailed(false)),
          put(actions.setDefaultClient(isDefaultCient))
        ]);
      } else {
        yield all([
          put(actions.getCategoryLoading(false)),
          put(actions.getCategorySuccess(false)),
          put(actions.getCategoryFailed(true)),
        ]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.getCategoryLoading(false)),
          put(actions.getCategorySuccess(false)),
          put(actions.getCategoryFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Intake Form Get Category Option",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.getCategoryLoading(false)),
        put(actions.getCategorySuccess(false)),
        put(actions.getCategoryFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Get Category Option",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.getCategoryLoading(false)),
      put(actions.getCategorySuccess(false)),
      put(actions.getCategoryFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Intake Form Get Category Option",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateCategorySaga(action) {
  let { id, categoryData, accessToken, selectAll } = action.payload;
  const { selectedClients, categoryId, isDefaultCient, standardClients, enterpriseClients} = yield select(
    (state) => state.intakeForm
  );
  // selectedClients.forEach((element) => {});
  // let tempArr = [];
  // selectedClients.forEach(function(item) {
  //   tempArr.push(item.value);
  // });
  let tempArr = [];
  standardClients.forEach(function(item) {
    tempArr.push(item.value);
  });
  enterpriseClients.forEach(function(item) {
    tempArr.push(item.value);
  });
  tempArr = tempArr.join();
  let categoryDatai = queryString.stringify({
    client_id: tempArr,
    name: categoryData.name,
    category_id: categoryId,
    available_for_all: selectAll,
    cabinet_brand_id: categoryData.cabinet_brand_id,
    status: 1,
    appliance_id: categoryData.appliance_id,
    option_type: categoryData.option_type ? categoryData.option_type:null,
    assigned_to_all_client: isDefaultCient?1:0,
  });

  yield all([
    put(actions.updateCategoryLoading(true)),
    put(actions.updateCategorySuccess(false)),
    put(actions.updateCategoryFailed(false)),
  ]);
  try {
    const response = yield call(
      putApiToken,
      `${apiUrl("UPDATE_CATEGORY_DATA_URL")}/${id}`,
      categoryDatai,
      accessToken
    );
    if (response.status === 200) {
      const {
        currentPage,
        maxCategoryPerPage,
        categorySortField,
        categorySortOrder,
        intakeType,
      } = yield select((state) => state.intakeForm);
      yield all([
        put(
          actions.getCategoryList(
            categorySortField,
            categorySortOrder,
            currentPage,
            maxCategoryPerPage,
            accessToken,
            intakeType
          )
        ),
        put(actions.updateCategorySuccess(true)),
        put(actions.updateCategoryLoading(false)),
        put(actions.updateCategoryFailed(false)),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Update Category Option",
          "Category option updated successfully!",
          "success",
          uid()
        )
      );
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([
          put(actions.updateCategoryLoading(false)),
          put(actions.updateCategorySuccess(false)),
          put(actions.updateCategoryFailed(true)),
        ]);
        yield put(
          generalActions.addToast(
            "Intake Form Update Category Option",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([
        put(actions.updateCategoryLoading(false)),
        put(actions.updateCategorySuccess(false)),
        put(actions.updateCategoryFailed(true)),
      ]);
      yield put(
        generalActions.addToast(
          "Intake Form Update Category Option",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([
      put(actions.updateCategoryLoading(false)),
      put(actions.updateCategorySuccess(false)),
      put(actions.updateCategoryFailed(true)),
    ]);
    yield put(
      generalActions.addToast(
        "Intake Form Update Category Option",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* deleteCategoryOptionSaga(action) {
  let { id, accessToken } = action.payload;
  yield all([put(actions.getCategoriesDataLoading(true))]);
  const {
    categorySortField,
    categorySortOrder,
    currentPage,
    maxCategoryPerPage,
    intakeType,
  } = yield select((state) => state.intakeForm);
  try {
    const response = yield call(
      deleteApiToken,
      `${apiUrl("DELETE_CATEGORY_OPTION")}/${id}`,
      accessToken,
      null
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield put(
          generalActions.addToast(
            "Intake Form Delete Category Option",
            "Category option deleted successfully!",
            "success",
            uid()
          )
        );
        yield all([
          put(
            actions.getCategoryList(
              categorySortField,
              categorySortOrder,
              currentPage,
              maxCategoryPerPage,
              accessToken,
              intakeType
            )
          ),
          put(actions.getCategoriesDataLoading(false)),
        ]);
      } else {
        yield all([put(actions.getCategoriesDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getCategoriesDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Intake Form Delete Category Option",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.getCategoriesDataLoading(false))]);
      yield put(
        generalActions.addToast(
          "Intake Form Delete Category Option",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getCategoriesDataLoading(false))]);
    yield put(
      generalActions.addToast(
        "Intake Form Delete Category Option",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getCabinateBrandOptionSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.getCabinateBrandOptionLoading(true))]);
  try {
    const response = yield call(
      getApiToken,
      apiUrl("CABINATE_BRAND_OPTION_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([
          put(actions.setCabinateBrandOption(responseData.data)),
          put(actions.getCabinateBrandOptionLoading(false)),
        ]);
      } else {
        yield all([put(actions.getCabinateBrandOptionLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getCabinateBrandOptionLoading(false))]);
        yield put(
          generalActions.addToast(
            "Cabinate Brand Dropdown",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.getCabinateBrandOptionLoading(false))]);
      yield put(
        generalActions.addToast(
          "Cabinate Brand Dropdown",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getCabinateBrandOptionLoading(false))]);
    yield put(
      generalActions.addToast(
        "Cabinate Brand Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* getapplianceOptionSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.setIntakeLoading(true))]);
  
  const filterParams = {
    intake_type: "appliances",
  };

  const sortParams = `&columns[0][data]=name&columns[0][name]=&columns[0][searchable]=true&columns[0][orderable]=false&columns[0][search][value]=${''}&columns[0][search][regex]=false&columns[1][data]= ${''}&columns[1][name]= &columns[1][searchable]= false&columns[1][orderable]= true&columns[1][search][value]= &columns[1][search][regex]= false&order[0][column]= 1&order[0][dir]= ${''}`;

  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_APPLIANCES_CATEGORIES_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data && responseData.data.data) {
        yield all([
          put(actions.setApplianceOption(responseData.data)),
          put(actions.setIntakeLoading(false)),
        ]);
      } else {
        yield all([put(actions.setIntakeLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.setIntakeLoading(false))]);
        yield put(
          generalActions.addToast(
            "Appliance Type Dropdown",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      let errorData = yield call([response, response.json]);
      yield all([put(actions.setIntakeLoading(false))]);
      yield put(
        generalActions.addToast(
          "Appliance Type Dropdown",
          errorData.message,
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.setIntakeLoading(false))]);
    yield put(
      generalActions.addToast(
        "Appliance Type Dropdown",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
