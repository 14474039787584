import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";

import { getApiToken, postApiToken } from "../../../../redux/apis";
import apiUrl from "../../../../configs/urls";
import { actions as authActions } from "../../Auth/_redux/authRedux";
import { actions as generalActions } from "../../../../redux/generalReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
  GET_EMAIL_NOTIFICATION_DATA: "GET_EMAIL_NOTIFICATION_DATA",
  SET_EMAIL_NOTIFICATION_DATA: "SET_EMAIL_NOTIFICATION_DATA",
  GET_EMAIL_NOTIFICATION_DATA_LOADING: "GET_EMAIL_NOTIFICATION_DATA_LOADING",
  SET_EMAIL_NOTIFICATION_DATAI: "SET_EMAIL_NOTIFICATION_DATAI",
  UPDATE_EMAIL_NOTIFICATION_DATA: "UPDATE_EMAIL_NOTIFICATION_DATA",
};
const initialemailNotificationState = {
  emailNotificationData: [
    { name: "", value: "", slug: "" },
    { name: "", value: "", slug: "" },
  ],
  emailNotificationDatai: {
    portaladminname: "",
    portaladminemail: "",
    portaladminslug: "",
    supportname: "",
    supportemail: "",
    supportemailslug: "",
  },
  getEmailNotificationDataLoading: false,
};

export const reducer = (state = initialemailNotificationState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMAIL_NOTIFICATION_DATA: {
      const { emailNotificationData } = action.payload;
      return {
        ...state,
        emailNotificationData: emailNotificationData,
      };
    }

    case actionTypes.SET_EMAIL_NOTIFICATION_DATAI: {
      const { emailNotificationDatai } = action.payload;
      return {
        ...state,
        emailNotificationDatai: emailNotificationDatai,
      };
    }

    case actionTypes.GET_EMAIL_NOTIFICATION_DATA_LOADING: {
      const { flag } = action.payload;
      return {
        ...state,
        getEmailNotificationDataLoading: flag,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  getEmailNotificationData: (accessToken) => ({
    type: actionTypes.GET_EMAIL_NOTIFICATION_DATA,
    payload: { accessToken },
  }),
  setEmailNotificationData: (emailNotificationData) => ({
    type: actionTypes.SET_EMAIL_NOTIFICATION_DATA,
    payload: { emailNotificationData },
  }),
  setEmailNotificationDatai: (emailNotificationDatai) => ({
    type: actionTypes.SET_EMAIL_NOTIFICATION_DATAI,
    payload: { emailNotificationDatai },
  }),
  getEmailNotificationDataLoading: (flag) => ({
    type: actionTypes.GET_EMAIL_NOTIFICATION_DATA_LOADING,
    payload: { flag },
  }),
  updateEmailNotificationData: (
    emailNotificationDatai,
    emailNotificationData,
    accessToken
  ) => ({
    type: actionTypes.UPDATE_EMAIL_NOTIFICATION_DATA,
    payload: { emailNotificationDatai, emailNotificationData, accessToken },
  }),
};

export function* saga() {
  yield takeLatest(
    actionTypes.GET_EMAIL_NOTIFICATION_DATA,
    getEmailNotificationSaga
  );
  yield takeLatest(
    actionTypes.UPDATE_EMAIL_NOTIFICATION_DATA,
    updateEmailNotificationDataSaga
  );
}

function* getEmailNotificationSaga(action) {
  let { accessToken } = action.payload;
  yield all([put(actions.getEmailNotificationDataLoading(true))]);
  let { emailNotificationData, emailNotificationDatai } = yield select(
    (state) => state.emailNotification
  );
  try {
    const response = yield call(
      getApiToken,
      apiUrl("GET_EMAIL_NOTIFICATION_DATA_URL"),
      null,
      accessToken
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        if (responseData.data.length) {
          let tempi = emailNotificationDatai;

          tempi["portaladminname"] = responseData.data[0]["name"];
          tempi["portaladminemail"] = responseData.data[0]["value"];
          tempi["portaladminslug"] = responseData.data[0]["slug"];
          tempi["supportname"] = responseData.data[1]["name"];
          tempi["supportemail"] = responseData.data[1]["value"];
          tempi["supportemailslug"] = responseData.data[1]["slug"];
          yield all([
            put(actions.setEmailNotificationDatai(tempi)),
            put(actions.getEmailNotificationDataLoading(false)),
          ]);
        } else {
          let tempi = emailNotificationDatai;

          tempi["portaladminname"] = "";
          tempi["portaladminemail"] = "";
          tempi["portaladminslug"] = "";
          tempi["supportname"] = "";
          tempi["supportemail"] = "";
          tempi["supportemailslug"] = "";
          yield all([
            put(actions.setEmailNotificationDatai(tempi)),
            put(actions.getEmailNotificationDataLoading(false)),
          ]);
        }
      } else {
        yield all([put(actions.getEmailNotificationDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getEmailNotificationDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Get Email Notification",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.getEmailNotificationDataLoading(false))]);
      yield put(
        generalActions.addToast(
          "Get Email Notification",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getEmailNotificationDataLoading(false))]);
    yield put(
      generalActions.addToast(
        "Get Email Notification",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}

function* updateEmailNotificationDataSaga(action) {
  let { emailNotificationDatai, accessToken } = action.payload;
  yield all([put(actions.getEmailNotificationDataLoading(true))]);

  let temp = {
    portaladminname: emailNotificationDatai["portaladminname"],
    portaladminemail: emailNotificationDatai["portaladminemail"],
    supportname: emailNotificationDatai["supportname"],
    supportemail: emailNotificationDatai["supportemail"],
  };

  try {
    const response = yield call(
      postApiToken,
      apiUrl("UPDATE_EMAIL_NOTIFICATION_DATA_URL"),
      accessToken,
      queryString.stringify(temp)
    );
    if (response.status === 200) {
      let responseData = yield call([response, response.json]);
      if (responseData && responseData.data) {
        yield all([put(actions.getEmailNotificationDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Email Notification",
            "Email notification data updated successfully",
            "success",
            uid()
          )
        );
      } else {
        yield all([put(actions.getEmailNotificationDataLoading(false))]);
      }
    } else if (response.status === 403) {
      let errorData = yield call([response, response.json]);
      if (errorData && errorData.statusCode && errorData.statusCode === 403) {
        yield put(authActions.setShouldLogout(true));
      } else {
        yield all([put(actions.getEmailNotificationDataLoading(false))]);
        yield put(
          generalActions.addToast(
            "Update Email Notification",
            "You do not have permission to perform this action!",
            "error",
            uid()
          )
        );
      }
    } else {
      yield all([put(actions.getEmailNotificationDataLoading(false))]);
      yield put(
        generalActions.addToast(
          "Update Email Notification",
          "Something went wrong!",
          "error",
          uid()
        )
      );
    }
  } catch (error) {
    console.log(error);
    yield all([put(actions.getEmailNotificationDataLoading(false))]);
    yield put(
      generalActions.addToast(
        "Update Email Notification",
        "Something went wrong!",
        "error",
        uid()
      )
    );
  }
}
