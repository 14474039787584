import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "../../../../../_metronic/_partials/controls";
import { Fields } from "./Fields";
import { Formik, Form } from "formik";

const Colors = ({allConfigs=[],saveConfigs}) =>{
  const [colors,setColors] = useState([]);
  const [initialValues,setInitialValues] = useState();

  
  useEffect(()=>{
    const c = allConfigs.filter(i=>i.group_name==="color");
    setColors(c)
    const newMapped = c.map((i)=>({[i.slug]:i.value}));
    const newObj = Object.assign({},...newMapped);
    setInitialValues(newObj)
  },[allConfigs])

  const submitForm = (values)=>{
    let configs = [];
    for(let key in values){
      configs.push({slug:key, value:values[key]});
    }
    console.log("configs",configs);
    saveConfigs({configs})
  }

  return (
      <>
      {initialValues?
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            submitForm(values);
            setTimeout(() => {
              setSubmitting(false);
            }, 400);
          }}
        >
          {({
            isSubmitting,
          }) => (
            <Form className="form form-label-right">
              <Card>
                <CardHeader style={{ border: 0 }} title="Colors">
                  <CardHeaderToolbar>
                    <button
                      type="submit"
                      className="btn btn-blue-primary"
                      disabled={isSubmitting}

                    >
                      Save
                    </button>
                  </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                  <Fields fields={colors}/>
                </CardBody>
              </Card>
            </Form>
          )}
        </Formik>
        :null
      }
    </>
  )
}

export default Colors;